.content-details {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0.625rem;
  height: calc(91.5vh - 2rem);
  margin: 1rem;
  .loading-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .content-details-header {
    border-radius: 0.625rem 0.625rem 0 0;
    background-color: white;
    padding: 1rem;
  }
  .content-details-body {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    gap: 1rem;
    flex: 1;
    padding: 0 1rem 1rem 1rem;
  }
  .content-details-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 1rem;
    border-radius: 0 0 0.625rem 0.625rem;
    border-top: 1px solid rgb(215, 229, 248);
    .content-actions {
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }
  }
}
