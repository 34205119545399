.termAndConditionModal {
  .modal {
    width: 50.78%;
    min-width: 650px;
    max-height: 85vh;
    .card {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}
