@import "../../../styles";

.common-table-wrapper {
  .common-table {
    width: 100%;
    border-collapse: collapse;
    thead {
      th {
        text-align: left;
        padding: 14px 8px;
        background: #fbfcfe;
        box-shadow: 0px -2px 0px 0px $color-border-default inset;
        margin-bottom: $spacing-03;
        color: $color-text-secondary;
        font-size: 0.8rem;
        text-transform: uppercase;
      }
    }
    tbody {
      background: $color-background-layer-01;
      td {
        background: $color-background-layer-01;
        box-shadow: 0px -2px 0px 0px $color-border-default inset;
        padding: 18px 8px;
        color: $color-text-secondary;
        font-size: 0.8rem;
        &.nested-table {
          padding: $spacing-03 0 0 $spacing-03;
        }
      }
    }
  }
  .pagination {
    margin-top: $spacing-04;
  }
}
