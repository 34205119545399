.social-media-modal {
  .modal {
    .horizontal-layout {
      display: flex;
      align-items: center;

      .horizontal-layoutItem-0 {
        flex-grow: 1;
      }

      .horizontal-layoutItem-1 {
        flex-grow: 2;
      }

      .horizontal-layoutItem-2 {
        flex-grow: inherit;
      }
    }
  }
}
