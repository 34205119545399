@import "../../styles/variables.scss";

$commonDropdownWidth: 160px;

.winningRewards-container-wrapper {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: calc(100vh - $header-height);
  overflow-y: hidden;
}

.winningRewards-container {
  background: rgba(255, 255, 255, 1);
  gap: 1rem;
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  height: 100%;
  flex-direction: column;
  border-radius: 10px;
  .winningRewards-header {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .input-wrapper {
    display: flex;
    justify-content: flex-start; /* Align input to the start */
    width: 30%; /* Adjust width as needed */
    padding-top: 20px;
  }
  .input-search {
    background-color: $white;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-radius: 0;
    width: 100%;

    input {
      font-size: 0.9rem;
    }
  }
}

.listing-view-container {
  overflow-y: auto;
  flex-grow: 1;
  /* This will make the container take up remaining space */
}

.winningRewards-type-modal .modal {
  max-width: 57.75rem;
  width: 72.1875%;

  .title {
    font-size: 1.125rem;
    font-weight: bold;
  }

  .type-select {
    width: 100%;
  }
}

.winningRewards-delete-modal {
  .modal-footer {
    justify-content: center;
  }
}
