.container-card {
  align-items: center;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 -2px 0 0 rgba(215, 229, 248, 1) inset;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
}

.page-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}
