.product-filter-container {
    width: 23%;
    overflow-y: auto;
    flex: 1;
    box-shadow: 4px 0px 24px -2px #005CBC14;

    .filter-wrap {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding-right: 0.5rem;
        padding-bottom: 1rem;

        .filter-type-setup-label {
            .MuiTypography-root {
                font-size: 0.875rem;
                font-weight: bold;
            }
        }

        .filter-type-setup-fields {
            display: flex;
            flex-wrap: wrap;
            row-gap: 1rem;
            column-gap: 1.5rem;

            .filter-type-setup-field {
                position: relative;
            }
        }

    }
}

.promoProducts-table {
    display: inline-block;
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;

    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;

        thead {
            border-bottom: 3px solid #d7e5f8;

            tr {
                th {
                    text-align: left;
                    padding: 14px 5px;
                    margin: 0;
                    margin-bottom: 8px;
                    color: #616161;
                    font-size: 11px;
                    text-transform: uppercase;
                    width: auto;
                }
            }
        }

        tbody {
            tr {
                border-bottom: 2px solid #d7e5f8;
            }

            td {
                margin: 0;
                padding: 14px 8px;
                color: #616161;
                font-size: 0.8rem;
            }
        }
    }
}