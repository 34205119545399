.hide_InputHeader .input-header {
    display: none;
}

.custom-timepicker-styles .icon-container{
    display: flex;
    position: relative;
    align-items: center;
    top: -4px;
    right: 0;
}