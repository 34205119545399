.partner-card-container {
  padding: 0px;
  display: flex;
  flex-direction: column;
  color: #3a3a3a;

  .card-header {
    display: none;
  }

  .partner-details {
    padding: 16px;
  }

  .partner-status-type {
    display: flex;
    gap: 0.25rem;
    margin-bottom: 8px;
  }

  .status {
    padding: 3px 8px;
    border-radius: 7px;
    color: white;
    font-weight: normal;
    font-size: 0.8em;
  }

  .status.active {
    background-color: #006a42;
  }

  .status.inactive {
    background-color: #eee;
    color: #000;
  }

  .type {
    background-color: #eee;
    border-radius: 16px;
    padding: 4px 8px;
    font-size: 0.8em;
  }

  .tooltip-parent {
    display: block;
  }

  .partner-name {
    font-size: 16px;
    margin: 15px 0 10px 0;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #3a3a3a;
    margin-block-end: 0;
    margin-block-start: 0;
  }

  .partner-meta {
    font-size: 0.7em;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }

  .meta-item {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: flex-start; /* Center-align items */
    text-align: left; /* Center-align text */
    margin-right: 1em;
  }

  .meta-item:last-child {
    margin-right: 0;
  }

  .meta-item span {
    display: block; /* Make each span a block element */
  }

  .meta-label {
    font-weight: bold; /* Make label text bold */
  }

  .partner-meta span {
    display: block;
    margin: 4px 0;
  }

  .partner-code {
    .code-label {
      color: #616161;
      font-size: 0.9em;
    }
    .code-item {
      margin-top: 10px;
      .input-item {
        .input {
          width: 100%;
        }
      }
    }
  }

  .button-group-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 16px;
    .button-group {
      button {
        padding: 0px var(--spacing-spacing-0, 5%);
      }
    }
  }

  .inactive {
    .button-group {
      button:last-child {
        color: #d40020;
      }
    }
  }
}
