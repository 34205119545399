.campaign-select {
  display: flex;
  flex-direction: column;
  height: 22.25rem;
  padding: 1rem;
  gap: 1rem;
  border-radius: 0.5rem;
  background-color: white;
  .campaign-select-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .input-item {
      flex: 1;
      max-width: 20rem;
    }
    .selection-header {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      .selected-campaign {
        font-size: 0.875rem;
      }
      .remove-selected {
        font-size: 0.875rem;
        color: rgb(0, 92, 188);
        cursor: pointer;
      }
    }
  }
  .loading-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .campaign-list {
    overflow-y: auto;
  }
}

.campaign-select-label {
  font-size: 0.75rem;
  color: rgb(97, 97, 97);
  margin-bottom: 0.5rem;
}

.campaign-select-modal .modal {
  width: 87.5%;
  max-width: 70rem;
  max-height: 40.125rem;
  .campaign-details {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 100%;
    gap: 1rem;
  }
  .modal-footer {
    display: none;
  }
}
