.partner-code-group-modal .MuiPaper-root {
  &.MuiPaper-root {
    min-width: 1000px;
    border: 10px solid var(--Colors-Blue-500, #d7e5f8);
    background: var(--layer-color-layer-01, #fbfcfe);
    .dropdown-input-row {
      width: 280px !important;
    }
    .bottom-button {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }
    .MuiSelect-select.Mui-disabled {
      background: var(--input-color-input-disabled, #d0d0d0);
      & ~ fieldset {
        border: 0px;
      }
    }
    .imageOverlay {
      min-height: 160px;
      height: unset;
    }
    .bottom-section {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 30px;
      .toggle {
        margin-right: 20px;
        span {
          margin-right: 8px;
        }
      }
    }
  }
  padding-top: 5px;
  .code-group-mod {
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 32px !important;
    .dropdown-container {
      z-index: 1;
    }

    .file-upload {
      width: auto !important;
    }

    .horizontal-layoutItem-item.horizontal-layoutItem-1 {
      .vertical-layoutItem-item.horizontal-layoutItem-2 {
        .MuiFormControl-root.MuiTextField-root {
          width: 100% !important;
        }
      }
    }
  }
  .edit-form {
    #cancel-selection {
      pointer-events: none;
    }
  }
  .toggle .label-text {
    padding-right: 8px !important;
  }
  .errorMessage {
    margin-top: 4px;
    font-size: 12px;
    color: red;
  }

  .codeWarningLimit {
    padding: 8px;
    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10;
      .label {
        font-size: 12px;
        display: flex;
      }
      .selected-percentage {
        color: #1976d2;
        font-weight: bold;
        font-size: 12px;
      }
    }
    .slider-control {
      margin-top: 8px !important;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      p {
        margin: 4px;
      }
    }
  }
  .disabled {
    .file-name-wrapper {
      background: #d0d0d0;
      pointer-events: none;
    }
  }
}
