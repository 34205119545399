.multistep-segment-select {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  .segment-item {
    align-items: center;
    width: 100%;
    border-radius: 0.5rem;
    padding: 1rem 0.5rem;
    gap: 1rem;
    background-color: rgb(239, 245, 252);
    border: 1px solid rgb(215, 229, 248);
    &.segment-added {
      background-color: rgb(251, 252, 254);
      .segment-content {
        gap: 1rem;
        .segment-homepage-image {
          width: 5.25rem;
          height: 2.8rem;
          border-radius: 0.5rem;
          object-fit: cover;
          margin-bottom: 0;
        }
      }
    }
    &.segment-disabled {
      background-color: rgba(255, 0, 0, 0.1); /* Light red background */
      border: 1px solid red;
      color: red;

      .added-segment span {
        color: red;
      }
    }

    .added-segment {
      width: 100%;
    }
    .button-group {
      button {
        justify-content: center;
        width: 2rem;
        height: 2rem;
        padding: 0.5rem;
      }
      &.add-new button {
        border-radius: 0.25rem;
      }
    }
    .button-group-small {
      height: fit-content;
      border-radius: 0.25rem;
    }
  }
}

.multistep-segment-select-label {
  font-size: 0.75rem;
  color: rgb(97, 97, 97);
  margin-bottom: 0.5rem;
}

.segment-select-steps-modal {
  .modal {
    padding: 1rem;

    div {
      width: 100%;
    }
    .title {
      font-size: 1.125rem;
    }
    .modal-footer {
      margin-top: auto;
    }
    flex: 1;
    .segment-steps-content-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      gap: 1rem;
      .required-fields {
        width: 100%;
        color: rgb(58, 58, 58);
        font-size: 0.75rem;
        font-weight: bold;
        text-align: end;
      }
    }
  }
}
