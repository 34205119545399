//variables
@import '../../../../styles/variables';

.preview-cards-campaigns-container {
  .preview-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;
    justify-content: end;
  }
  .preview-carousel {
    display: flex;
    margin-top: 120px;
    .arrow {
      margin-top: 100px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      .icon {
        display: inline-block;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        padding-top: 3px;
      }
    }
    .preview-carousel-items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 385px;
      margin: 0 5px 0 10px;
      gap: 8px;
      .preview-card-item {
        width: 120px;
        .image {
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .preview-card-item {
    flex: 0 0 auto;
    width: 144px;
    height: 260px;
    border-radius: 10px;
    overflow: hidden;
    border: 0.7px solid $gray;
    .image {
      text-align: center;
      border-radius: 5px 5px 0 0;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .text {
      padding: 5px;
    }
    .title {
      width: 100%;
      font-weight: bold;
      font-size: 11.5px;
      &.disabled {
        color: $dark-gray;
      }
    }
    .secondary-text {
      width: 100%;
      font-size: 10px;
      margin-top: 5px;
      margin-bottom: 10px;
      &.disabled {
        color: $dark-gray;
      }
    }
    .bottom-text {
      width: 100%;
      font-size: 9px;
      margin-top: 50px;
    }
    .label-text {
      width: 100%;
      font-size: 9.5px;
    }
    .text-input {
      margin-top: 12px;
      width: 100%;
      font-size: 11px;
    }
    .input-box {
      margin-top: 4px;
      width: 100%;
      font-size: 12px;
      p {
        border: 1px solid #C0C0C0;
        border-radius: 5px;
        font-size: 12px;
        padding: 2px 5px;
        height: 27px;
        width: 100%;
      }
    }
    .coming-soon {
      margin-top: 83px;
      width: 100%;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    }
    .ended {
      margin-top: 83px;
      width: 100%;
      font-size: 11px;
      text-align: center;
      letter-spacing: -0.2px;
      &.disabled {
        color: $dark-gray;
      }
    }
    .completed-text {
      margin-top: 83px;
      width: 100%;
      font-size: 14px;
      text-align: center;
      color: #008553;
      display: flex;        
      align-items: center;
      justify-content: center; 
      font-weight: bold;
      .icon {
        margin-left: 2px;
      }
    }
    .btn-preview {
      margin-top: 5px;
      width: 100%;
      text-align: center;
      display: ruby-text;
      &.btn-mt {
        margin-top: 77px;
      }
      p {
        border-radius: 5px;
        width: 95%;
        font-size: 12px;
        margin: 0 auto;
        padding: 2px 5px;
        font-weight: normal;
      }
    }
    .btn-preview:not(.disabled):hover {
      cursor: pointer;
    }
  }
}
