.boosted-reward-group-modal {
  .modal {
    width: 87.5%;
    min-height: 91%;
    overflow-y: scroll;
    padding: 1rem;
    .boost-reward-group-segment-select {
      background-color: rgb(239, 245, 252);
      padding: 1rem;
      border-radius: 0.5rem;
      .segment-item {
        align-items: center;
        width: 100%;
        border-radius: 0.5rem;
        padding: 1rem 0.5rem;
        gap: 1rem;
        border: 1px solid rgb(215, 229, 248);
        &.segment-added {
          background-color: rgb(251, 252, 254);
        }
        &.segment-disabled {
          background-color: rgba(255, 0, 0, 0.1); /* Light red background */
          border: 1px solid red;
          color: red;

          .added-segment span {
            color: red;
          }
        }

        .added-segment {
          width: 100%;
        }
        .button-group button {
          justify-content: center;
          width: 2rem;
          height: 2rem;
          padding: 0.5rem;
          border-radius: 0.25rem;
        }
        .button-group-small {
          height: fit-content;
        }
      }
    }

    .boost-reward-group-segment-select {
      div {
        width: 100%;
      }
      .title {
        font-size: 1.125rem;
      }
      .modal-footer {
        margin-top: auto;
      }
      .segment-modal-content {
        display: flex;
        flex-direction: row;
        height: 100%;
        gap: 0.5rem;
        overflow-y: auto;
        .segment-filters {
          display: flex;
          flex-direction: column;
          flex-basis: 44.8%;
          height: 100%;
          gap: 0.5rem;
          padding: 0.5rem;
          border-radius: 0.5rem;
          background-color: white;
          box-shadow: 4px 0px 24px -2px rgba(0, 92, 188, 0.08);
          .segment-filters-header {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            padding: 0.5rem 0rem;
            .input-search {
              width: auto;
              input {
                font-size: 0.75rem;
              }
            }
          }
        }
        .grid-container {
          display: grid;
          gap: 1rem;
          grid-template-columns: repeat(auto-fill, minmax(11.875rem, 1fr));
          overflow-y: auto;
          grid-auto-rows: min-content;
          .segment-card {
            display: flex;
            flex-direction: column;
            height: fit-content;
            padding: 1rem;
            gap: 0.5rem;
            border-radius: 0.625rem;
            background-color: white;
            box-shadow: 0px 0px 17px 0px rgba(0, 92, 188, 0.08);
            cursor: pointer;
            &.selected {
              border: 2px solid rgb(0, 106, 66);
            }
            .segment-info-row {
              display: flex;
              flex-direction: row;
              gap: 16px;
            }
          }
        }
        .selected-segments {
          display: flex;
          flex-direction: column;
          flex-basis: 44.8%;
          gap: 0.5rem;
          padding: 0.5rem;
          border-radius: 0.3125rem;
          background-color: rgb(243, 247, 253);
          .selected-segments-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .remove-selected {
              color: rgb(0, 92, 188);
              cursor: pointer;
            }
          }
          .selected-segments-list {
            .badge {
              height: auto;
              align-items: flex-start;
            }
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}

.no-segments {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}
.campaign-segment-copy-confirmation-modal {
  .overlay {
    z-index: 9999;
  }
  .modal {
    z-index: 10000;
  }
}
