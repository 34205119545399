@import "../../styles/variables.scss";

.container-card {
  align-items: center;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 -2px 0 0 rgba(215, 229, 248, 1) inset;
  position: relative;
  .notification-wrapper {
    position: absolute;
    top: 0%;
    width: 100%;
    .notification {
      width: 100%;
    }
  }
  .no-records {
    display: flex;
    align-items: center;
    width: 100%;
    height: 200px;
    justify-content: center;
    font-weight: 700;
    color: grey;
    font-size: 20px;
  }
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
}

.page-content {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
}

.survey-search-filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
  .filter-container-search {
    width: 25%;
  }
  .filter-container-popover {
    width: 70%;
    margin-bottom: 1%;
    .horizontal-layout {
      display: flex;
      flex-wrap: wrap;
      gap: 10px; // Adjust gap as needed
    }
    .horizontal-layoutItem-item {
      flex: 1 1;
      margin-top: 5px;

      .horizontal-layoutItem-item {
        &.horizontal-layoutItem-0 {
          flex: 1 2;
        }

        &.horizontal-layoutItem-1 {
          flex: 1 2;
        }

        &.horizontal-layoutItem-2 {
          flex: 1 2;
        }
      }

      &.horizontal-layoutItem-0,
      &.horizontal-layoutItem-1,
      &.horizontal-layoutItem-2 {
        .MuiFormControl-root {
          .MuiOutlinedInput-root {
            .MuiOutlinedInput-notchedOutline {
              border-color: #add8e6;
              /* border-radius: 15px; */
            }

            .MuiInputBase-input {
              color: #3a3a3a;

              &::placeholder {
                color: #3a3a3a;
                opacity: 1;
              }
            }
          }
        }
      }
      &.horizontal-layoutItem-3 {
        .MuiFormControl-root {
          .MuiOutlinedInput-root {
            .MuiOutlinedInput-notchedOutline {
              border-color: $light-blue;
              /* border-radius: 15px; */
            }

            .MuiInputBase-input {
              color: $primary-color;

              &::placeholder {
                color: $primary-color;
                opacity: 1;
              }
            }

            .MuiInputAdornment-root {
              .MuiButtonBase-root {
                .MuiSvgIcon-root {
                  color: $primary-color;
                }
              }
            }
          }
        }
      }
      &.horizontal-layoutItem-4 {
        .MuiFormControl-root {
          .MuiOutlinedInput-root {
            .MuiOutlinedInput-notchedOutline {
              border-color: $light-blue;
              /* border-radius: 15px; */
            }

            .MuiInputBase-input {
              color: $primary-color;

              &::placeholder {
                color: $primary-color;
                opacity: 1;
              }
            }

            .MuiInputAdornment-root {
              .MuiButtonBase-root {
                .MuiSvgIcon-root {
                  color: $primary-color;
                }
              }
            }
          }
        }
      }
      float: left;
      width: 130px;
      margin-right: 10px;
    }
  }
  .filter-button-group {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }
  .input-search {
    border-top: 0;
    border-bottom: 1px solid #3a3a3a;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    &:focus {
      border-top: 0;
      border-bottom: 1px solid #005cbc;
      border-right: 0;
      border-left: 0;
    }
    &:focus-within {
      border-top: 0;
      border-bottom: 1px solid #005cbc;
      border-right: 0;
      border-left: 0;
    }
    &:active {
      border-top: 0;
      border-bottom: 1px solid #005cbc;
      border-right: 0;
      border-left: 0;
    }
    input {
      &:focus {
        &::placeholder {
          color: #005cbc;
        }
      }
      font-size: 14px;
    }
  }
}

.survey-filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  gap: 16px;
  width: 100%;
  margin: 20px 0;
  .filter-button-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }
  .input-search {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-radius: 0;
  }

  .dropdown-input-row {
    width: 100%;
    input {
      border-radius: 15px;
      width: 100%;
      border: 1px solid #d7e5f8;
      color: #005cbc;
    }
    span {
      color: #005cbc;
    }
  }
  .dropdown-title {
    display: none;
  }
  .vertical-layoutItem-item {
    float: left;
    width: 130px;
  }
  .vertical-layout {
    align-items: flex-end;
    justify-content: center;
    flex-direction: row;
  }
  .MuiOutlinedInput-root {
    input,
    svg {
      color: #005cbc;
    }
  }
  .vertical-layoutItem-item.horizontal-layoutItem-3
    .MuiFormControl-root
    .MuiOutlinedInput-root
    .MuiInputBase-input {
    color: #005cbc;
  }
  .vertical-layoutItem-item.horizontal-layoutItem-3
    .MuiFormControl-root
    .MuiOutlinedInput-root
    .MuiOutlinedInput-notchedOutline {
    border-color: #d7e5f8;
  }
  input::placeholder {
    color: #005cbc;
  }
}

.card {
  .card-header {
    display: none;
  }
  .card-footer {
    display: none;
  }
  .card-content {
    align-items: initial;
  }
  .sweepstakes-header {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

.card .card-footer {
  display: none;
}

.status {
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 10px;
  border-radius: 4px;
  padding: 2px 4px;
  &.draft {
    background-color: rgb(97, 97, 97);
  }
  &.scheduled {
    background-color: rgb(255, 204, 73);
    color: rgb(58, 58, 58);
  }
  &.completed {
    background-color: rgb(179, 218, 203);
    color: rgb(58, 58, 58);
  }
  &.ended {
    background-color: rgb(102, 126, 136);
  }
}

.sweepstakes-info {
  display: flex;
  gap: 16px;
  .meta-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }
}

.button-group-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 0px;
  &.deletable {
    .button-group button:last-child {
      color: #d40020;
    }
  }
}

.button-group-wrapper {
  &.deletable {
    .button-group button:last-child {
      color: #d40020;
    }
  }
}

.datatable {
  .header {
    display: none;
  }
  .footer {
    display: none;
  }
}

.grid-container {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  .no-records {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* This aligns the content vertically in the center */
    font-weight: 700;
    color: grey;
    font-size: 20px;
    height: 100vh; /* Ensures the container takes up the full viewport height */
  }
}

.custom_chip.active {
  background-color: green;
}

.custom_chip.inactive {
  background-color: grey;
}

.custom_chip.published {
  background-color: rgb(255, 204, 73);
}

.custom_chip.completed {
  background-color: rgb(179, 218, 203);
}

.custom_chip.pinned {
  background-color: var(--cyan500);
}

.custom_chip.content {
  padding: 4px 8px;
  color: white;
  border-radius: 16px;
  text-align: center;
  max-width: "100px";
  font-size: "12px";
}
