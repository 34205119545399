.details-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: 0px 0px 24px 0px #005cbc14;
  .details-container-header {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
  }
  .details-container-content-wrapper {
    background-color: #eff5fc;
    padding: 1rem;
  }
}
