@import "../../styles";

.filter-item {
  line-height: normal;
  font-size: 14px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
    color: $color-interaction-active;
  }
}
.accordion {
  .content-expander {
    padding-top: $spacing-03;
    height: 150px;
    overflow: auto;
  }
}
.custom-accordion-item {
  .summary {
    .buttonBase {
      border-top-width: 0;
    }
  }
}
.not-found {
  margin-top: $spacing-06;
  display: inline-block;
}
.loading-rewards {
  display: flex;
  justify-content: center;
  margin-top: $spacing-06;
}

.MuiAccordion-root {
  &.campaign-add-rewards-filter {
    background-color: transparent;
    border: none;
    box-shadow: none;
    &::before {
      height: 0px;
    }
    &.Mui-expanded {
      margin: 0;
    }

    .MuiAccordionDetails-root {
      padding-top: 0;
    }
    .MuiCollapse-root {
      max-height: 200px;
      overflow-y: scroll;
    }

    .MuiAccordionSummary-content {
      font-size: 14px;
      font-weight: bold;
    }

    .filter-item {
      padding: $spacing-03 0;
    }
  }
}
