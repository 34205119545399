.customDatePickerStyles input {
    padding: 8px;
    font-size: 13.6px;
}

.zIndex-increase{
    z-index: 3334;
}

.MuiInputBase-root.Mui-disabled{
    background: var(--input-color-input-disabled, #D0D0D0);
    fieldset{
        border: 0px;
    }
}