//variables
@import '../../../styles/variables';

.list-container {
  .list-table {
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    thead {
      th {
        text-align: left;
        padding: 14px 8px;
        background: $background-light-white;
        box-shadow: 0px -2px 0px 0px rgba(215, 229, 248, 1) inset;
        margin-bottom: 8px;
        color: $dark-gray;
        font-size: 0.8rem;
        text-transform: uppercase;
        &.column-name {
          width: auto;
        }
        &.column-description {
          width: 50%;
        }
        &.column-actions {
          width: 20%;
        }
      }
    }
    tbody {
      box-shadow: 0px 0px 24px 0px rgba(0, 92, 188, 0.08);
      background: $background-light-white;
      td {
        background: $background-light-white;
        box-shadow: 0px -2px 0px 0px rgba(215, 229, 248, 1) inset;
        padding: 18px 8px;
        color: $dark-gray;
        font-size: 0.8rem;
        &.no-space-title {
          max-width: 190px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .button-group-wrapper {
          .button-group {
            button {
              padding: 0px var(--spacing-spacing-0, 18px);
              font-size: 0.9rem;
              span {
                font-size: 0.9rem;
              }
            }
          }
        }
      }
    }
  }
}






