//variables
@import "../../../styles/variables";

.widget-list-container {
  .widget-list-table {
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    thead {
      th {
        text-align: left;
        padding: 14px 8px;
        background: $background-light-white;
        box-shadow: 0px -2px 0px 0px rgba(215, 229, 248, 1) inset;
        margin-bottom: 8px;
        color: $dark-gray;
        font-size: 0.8rem;
        text-transform: uppercase;
        &.column-status {
          width: 11%;
        }
        &.column-name {
          width: 30%;
        }
        &.column-format {
          width: 14%;
        }
        &.column-type {
          width: 18%;
        }
        &.column-actions {
          width: 18%;
        }
      }
    }
    tbody {
      box-shadow: 0px 0px 24px 0px rgba(0, 92, 188, 0.08);
      background: $background-light-white;
      td {
        background: $background-light-white;
        box-shadow: 0px -2px 0px 0px rgba(215, 229, 248, 1) inset;
        padding: 18px 8px;
        color: $dark-gray;
        font-size: 0.8rem;
        &.no-space-title {
          max-width: 190px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &.widget-status-type {
          justify-content: space-between;
          margin-bottom: 8px;
          .partner-card-tags {
            display: flex;
            gap: 0.25rem;
          }
          .status {
            padding: 4px 8px;
            border-radius: 7px;
            color: white;
            font-weight: normal;
            font-size: 0.8em;
          }

          .status.active {
            background-color: $green-primary;
          }

          .status.inactive {
            background-color: #eee;
            color: $black;
          }
        }
        .button-group-container {
          .button-group {
            button {
              padding: 0px var(--spacing-spacing-0, 18px);
              font-size: 0.9rem;
              span {
                font-size: 0.9rem;
              }
            }
          }
        }

        .inactive {
          .button-group {
            button:last-child {
              color: $red-primary;
            }
          }
        }
      }
    }
  }
}
