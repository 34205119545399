// Colors
$bg-body-color-light: #f3f7fd;
$bg-dark: #272727;
$white: #fff;
$black: #000;
$gray: #DFDFDF;
$medium-gray: #C0C0C0;
$dark-gray: #616161;
$almost-black: #3A3A3A;
$lightgreen: #B3DACB;
$light-blue: #D7E5F8;
$success: #009639;
$green-primary: #006A42;
$yellow-published: #FFCC49;
$gray-completed: #667E88;
$red-primary: #D40020;
$primary-color: #005CBC;
$secondary-color: #E5F2FC;
$background-light-white: #FBFCFE;
$light-gray: #A0A0A0;
$segment-detail-card-background: #EFF5FC;

// z-index
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-offcanvas: 1050;
$zindex-modal: 1060;
$zindex-popover: 1070;
$zindex-tooltip: 1080;

// font sizes
$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem
);

$header-height: 8.5vh;