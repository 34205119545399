.partnerCodeGroup-modal {
  .modal {
    width: 60%;
    background-color: #fbfcfe;
    padding-top: 25px;
    .summary-mod {
      max-height: 500px;
      width: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      background-color: #fff !important;
      h3 {
        margin: 3% 0;
      }
      .card {
        width: 100%;
        background-color: #eff5fc;
        .summary-content-wrapper {
          display: flex;
          align-items: flex-start;
          .summary-content {
            display: flex;
            align-items: center;
            /* justify-content: center; */
            p.title {
              color: #3a3a3a;
              font-size: 12px;
              font-weight: 700;
              margin: 0;
              margin-right: 10px;
            }
            p.content {
              color: #3a3a3a;
              font-size: 12px;
              margin-right: 20px;
            }
          }
          .bg-color-content {
            background-color: #eff5fc;
            max-width: 659px;
            padding: 1%;
          }
        }
        img {
          max-width: 330px;
        }
      }
    }
  }
  &.hide-btn {
    .button {
      display: none;
    }
  }
  .download-sample-file {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .download-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2% 0;
  }

  .code-imported {
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: #b3dacb;
    border-radius: 6px;
    color: #3a3a3a;
    padding: 2px 4px;
  }
}
