/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollBar::-webkit-scrollbar {
  // display: none;
  scrollbar-width: thin;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollBar {
  // -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: thin;
  /* Firefox */
}

.sidebarStyles {
  height: 100vh !important;
  width: auto;
  .sublink-container {
    height: auto;
  }
}

.sidebarStyles-open {
  .sublink-container {
    padding: 1.5rem 1rem 1.5rem 4rem;
  }
}

.sidebarStyles-close {
  .sublink-container {
    padding: 0rem;
  }
}
