//variables
@import "../../../styles/variables";

.summery-card-container {
  padding: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.campaign-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin: 0 8px 8px 8px;
}
.campaign-header h3 {
  margin: 0;
  height: auto;
}
.summary-card-details {
  background: #005cbc14 !important;
  border-radius: 8px;
  padding: 16px;
  .two-column {
    & > div:first-child {
      & > div:first-child {
        & > div:first-child,
        & > div ~ div {
          display: flex;
        }
      }
    }
  }
}

.campaign-card-container {
  padding: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-width: 350px;
  .button-group button span[title="delete"] {
    color: $red-primary;
  }

  .campaign-image-wrapper {
    max-height: 150px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .campaign-image {
    width: 100%;
    padding-top: 0;
    display: block;
    object-fit: contain;
  }

  .campaign-details {
    padding: 0 !important;
  }

  .campaign-status-type {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }

  .status {
    padding: 4px 8px;
    border-radius: 6px;
    color: white;
    font-size: 11px;
    font-weight: normal;
    color: $almost-black;
    align-items: center;
    display: flex;
  }

  .status.active {
    background-color: $green-primary;
    color: #fff;
  }

  .status.inactive {
    background-color: #dfdfdf;
  }

  .status.draft {
    background-color: $dark-gray;
    color: #fff;
  }
  .status.published {
    background-color: #ffcc49;
  }
  .status.completed {
    background-color: #667e88;
    color: #fff;
  }
  .field-pair {
    display: flex;
    flex-direction: column;
    grid-column: span 1;
  }

  .field-pair.full-width {
    grid-column: span 2;
  }

  .field-pair-two-column {
    flex-basis: 70%; /* Each pair takes up half the width of the container */
    display: flex;
    flex-direction: column; /* Stack label and value vertically */
    margin-bottom: 8px; /* Space between rows */
  }

  .field-pair-4-col {
    flex-basis: 16.5%; /* Each pair takes up half the width of the container */
    display: flex;
    flex-direction: column; /* Stack label and value vertically */
    margin-bottom: 8px; /* Space between rows */
  }
}

.type {
  background-color: #aad2e2;
  border-radius: 6px;
  padding: 4px 8px;
  font-size: 11px;
  color: $almost-black;
  text-align: center;
  white-space: nowrap;
}
.type.standard-campaign {
  background: rgba(170, 210, 226, 1); /* Color for standard type */
}

.type.po1 {
  background-color: rgba(255, 220, 187, 1); /* Color for PO1 type */
}

.type.action-based {
  background-color: rgba(179, 218, 203, 1); /* Color for Action Based type */
}

.type.limitless {
  background-color: rgba(255, 236, 187, 1); /* Color for Limitless type */
}

.campaign-name {
  font-size: 14px;
  color: $almost-black;
  font-weight: bold;
  margin: 20px 0 20px 0;
  height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.no-space-title {
  max-width: 180px;
}

.campaign-short-name {
  margin: 0 0 16px 0;
  color: #666;
  font-size: 10px;
}

.button-group-container {
  width: 100%;
  margin-top: 16px;
  overflow-x: auto;
  .button-group {
    width: 100%;
    button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.additional-fields-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Always two equal columns */
  gap: 8px; /* Space between items */
}

.field-row {
  display: contents;
}

.field-label {
  font-weight: bold;
  font-size: 11px;
  margin-bottom: 4px;
  color: $almost-black;
}

.field-value {
  font-size: 11px;
  color: $almost-black;
}

@media (max-width: 768px) {
  .additional-fields-container {
    grid-template-columns: 1fr; /* Stack fields vertically on smaller screens */
  }
  .field-pair {
    grid-column: span 1; /* Each field pair takes up full width */
  }
}
@media (min-width: 1300px) and (max-width: 1599px) {
  .no-space-title {
    max-width: 200px;
  }
}
@media (min-width: 1600px) {
  .no-space-title {
    max-width: 270px;
  }
}
