.bg-dark {
    background-color: $bg-dark;
}

.body-background {
    background-color: $bg-body-color-light;
}

/* Primary and secondary colors */
.text-success {
    color: $success;
}

.bg-success {
    background-color: $success;
}

.text-white {
    color: $white;
}

.bg-white {
    background-color: $white;
}

.bg-gray {
    background-color: $gray;
}

.text-gray {
    color: $gray;
}


.bg-lightblue {
    background-color: $light-blue;
}

.text-lightblue {
    color: $light-blue;
}

.bg-lightgreen {
    background-color: $lightgreen;
}

.text-lightgreen {
    color: $lightgreen;
}

.bg-primary0 {
    background-color: $primary-color;
}

.bg-secondary0 {
    background-color: $secondary-color;
}

.text-primary0 {
    color: $primary-color;
}

.text-secondary0 {
    color: $secondary-color;
}