.summery-card-container {
  padding: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  // max-width: 350px;
}

.campaign-card-container {
  padding: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-width: 350px;
}

.campaign-image-wrapper {
  max-height: 150px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.campaign-image {
  width: 90%;
  padding-top: 16px;
  display: block;
  border-radius: 5px;
  object-fit: contain;
}

.campaign-details {
  padding: 16px !important;
}

.campaign-status-type {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
}

.status {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 16px;
  color: white;
  font-weight: bold;
  font-size: 0.8em;
}

.status.active {
  background-color: green;
}

.status.inactive {
  background-color: rgb(168, 164, 164); /* Updated color for inactive status */
}

.status.draft {
  background-color: rgba(97, 97, 97, 1); /* Added color for draft status */
}
.status.published {
  background-color: rgb(255, 204, 73); /* Added color for draft status */
}

.type {
  text-align: center;
  background-color: rgba(170, 210, 226, 1);
  border-radius: 16px;
  padding: 4px 8px;
  font-size: 0.8em;
}
.type.standard_campaign {
  background: rgba(170, 210, 226, 1); /* Color for standard type */
}

.type.po1 {
  background-color: rgba(255, 220, 187, 1); /* Color for PO1 type */
}

.type.action-based {
  background-color: rgba(179, 218, 203, 1); /* Color for Action Based type */
}

.type.limitless_campaign {
  background-color: rgba(255, 236, 187, 1); /* Color for Limitless type */
}

.campaign-name {
  font-size: 14px;
  color: rgba(58, 58, 58, 1);
  font-weight: bold;
  margin: 20px 0 10px 0;
}

.campaign-short-name {
  margin: 0 0 16px 0;
  color: #666;
  font-size: 10px;
}

.campaign-meta {
  font-size: 0.9em;
  margin-bottom: 16px;
  display: flex; /* Enable flex layout */
  justify-content: space-between; /* Distribute space evenly around items */
}

.meta-item {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: flex-start; /* Center-align items */
  text-align: left; /* Center-align text */
  margin-right: 2em;
}

.meta-item span {
  display: block; /* Make each span a block element */
}

.meta-label {
  font-weight: bold; /* Make label text bold */
}

.campaign-meta span {
  display: block;
  margin: 4px 0;
}

// .campaign-dates {
//   display: flex; /* Enable flex layout */
//   align-items: flex-start; /* Align items to the start of the flex direction */
// }

// .date-item {
//   font-size: 10px;
//   display: flex;
//   flex-direction: column; /* Stack children vertically */
//   align-items: flex-start; /* Align items to the start */
//   text-align: left; /* Text aligned to the left */
//   gap: 4px;
// }

.date-label {
  font-weight: bold; /* Make label text bold */
}

.button-group-container {
  width: 100%;
  margin-top: 16px;
  overflow-x: auto;
  .button-group {
    width: 100%;
    button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.additional-fields-container {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap onto the next line */
  justify-content: space-between; /* Maintain spacing between items */
  align-items: flex-start; /* Align items at the start of the flex direction */
}

.field-pair {
  flex-basis: 30% !important; /* Each pair takes up half the width of the container */
  display: flex;
  flex-direction: column; /* Stack label and value vertically */
  margin-bottom: 8px; /* Space between rows */
}

.field-pair-two-column {
  flex-basis: 70%; /* Each pair takes up half the width of the container */
  display: flex;
  flex-direction: column; /* Stack label and value vertically */
  margin-bottom: 8px; /* Space between rows */
}

.field-pair-4-col {
  flex-basis: 16.5%; /* Each pair takes up half the width of the container */
  display: flex;
  flex-direction: column; /* Stack label and value vertically */
  margin-bottom: 8px; /* Space between rows */
}

.field-label {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 4px; /* Small space between the label and the value */
}

.field-value {
  font-size: 12px;
  color: #666; /* Gray color for the value, consistent with other metadata */
}

@media (max-width: 768px) {
  .field-pair {
    flex-basis: 100%; /* Stack fields vertically on smaller screens */
  }
}
//}
