// Colors
$color-text-primary: #3a3a3a;
$color-text-secondary: #616161;
$color-text-tertiary: #a0a0a0;
$color-text-inverse: #efefef;
$color-text-black: #000000;
$color-text-white: #ffffff;
$color-text-disabled: #a0a0a0;

$color-gray-100: #dfdfdf;
$color-gray-500: #616161;
$color-green-150: #b3dacb;
$color-green-600: #006a42;
$color-yellow-400: #ffcc49;

$color-background-default: #f3f7fd;
$color-background-layer-01: #fbfcfe;
$color-background-layer-02: #eff5fc;
$color-background-layer-03: #b2caea;
$color-background-inverse: #272727;
$color-background-utility-dark: #818181;
$color-background-utility-light: #dfdfdf;
$color-background-overlay: #272727;

$color-border-default: #d7e5f8;
$color-border-divider: #dfdfdf;

$color-feedback-success: #008553;
$color-feedback-success-background: #cce7dd;
$color-feedback-error: #d40020;
$color-feedback-error-background: #fbe6e9;
$color-feedback-info: #0078a7;
$color-feedback-info-background: #cceaf6;
$color-feedback-warning: #fff2d2;
$color-feedback-warning-background: #fff2d2;
$color-feedback-advice: #ff891c;

$color-icon-default: #3a3a3a;

$color-interaction-active: #005cbc;
$color-interaction-hover: #dfeaf9;
$color-interaction-focus: #337dc9;
$color-interaction-disabled: #a0a0a0;
$color-interaction-disabled-background: #d0d0d0;
$color-interaction-form-background: #fbfcfe;
$color-interaction-form-border: #c0c0c0;

$color-elevation-01: #e6eff8;
$color-elevation-02: #efefef;
$color-elevation-03: #e6eff8;
$color-elevation-04: #e6eff8;

$color-gradient-primary-01: linear-gradient(136deg, #0054ac 24.74%, #3387df 62.9%);
$color-gradient-primary-02: linear-gradient(145deg, #337dc9 20.55%, #004a96 79.48%);
$color-gradient-secondary-01: linear-gradient(90deg, #0097bd 0%, #66c1d7 100%);
$color-gradient-secondary-02: linear-gradient(180deg, #0097bd 0%, #66c1d7 100%);

$color-button-primary: #005cbc;
$color-button-primary-hover: #004a96;
$color-button-primary-pressed: #003771;
$color-button-secondary: #117d9f;
$color-button-secondary-hover: #0e647f;
$color-button-secondary-pressed: #0a4b5f;
$color-button-outlined: #005cbc;
$color-button-outlined-hover: #004a96;
$color-button-borderless: #005cbc;
$color-button-outlined-hover: #004a96;
$color-button-disabled: #d0d0d0;

$color-buttongroup-default: #e7effb;
$color-buttongroup-hover: #eff5fc;
$color-buttongroup-selected: #dfeaf9;
$color-buttongroup-active: #005cbc;
$color-buttongroup-text: #005cbc;
$color-buttongroup-border: #d7e5f8;
$color-buttongroup-disabled: #fbfcfe;

$color-link-default: #005cbc;
$color-link-hover: #004a96;
$color-link-disabled: #a0a0a0;

// Spacing
$spacing-01: 0.125rem;
$spacing-02: 0.25rem;
$spacing-03: 0.5rem;
$spacing-04: 1rem;
$spacing-05: 1.5rem;
$spacing-06: 2rem;
$spacing-07: 2.5rem;
$spacing-08: 3rem;
$spacing-09: 4rem;
$spacing-10: 5rem;
$spacing-11: 6rem;
$spacing-12: 10rem;

// Typography
.font-heading-h1-bold {
  font-size: 4rem;
  font-weight: bold;
  line-height: 150%;
}

.font-heading-h2-bold {
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 140%;
}

.font-heading-h3-bold {
  font-size: 2.25rem;
  font-weight: bold;
  line-height: 155%;
}

.font-heading-h4-bold {
  font-size: 2rem;
  font-weight: bold;
  line-height: 175%;
}

.font-heading-h4-medium {
  font-size: 2rem;
  font-weight: 600;
  line-height: 175%;
}

.font-heading-h5-bold {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 166%;
}

.font-heading-h5-medium {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 166%;
}

.font-heading-h6-bold {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 177%;
}

.font-heading-h6-medium {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 177%;
}

.font-body-bold {
  font-size: 1rem;
  font-weight: bold;
  line-height: 150%;
}

.font-body-medium {
  font-size: 1rem;
  font-weight: 600;
  line-height: 150%;
}

.font-body-regular {
  font-size: 1rem;
  font-weight: normal;
  line-height: 150%;
}

.font-body-caps {
  font-size: 1rem;
  font-weight: bold;
  line-height: 150%;
  text-transform: uppercase;
}

.font-small-bold {
  font-size: 0.85rem;
  font-weight: bold;
  line-height: 133%;
}

.font-small-medium {
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 133%;
}

.font-small-regular {
  font-size: 0.85rem;
  font-weight: normal;
  line-height: 133%;
}

.font-small-caps {
  font-size: 0.85rem;
  font-weight: bold;
  line-height: 133%;
  text-transform: uppercase;
}

.font-xsmall-bold {
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 133%;
}

.font-xsmall-regular {
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 133%;
}

.font-xsmall-caps {
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 133%;
  text-transform: uppercase;
}

.font-xxsmall-bold {
  font-size: 0.625rem;
  font-weight: bold;
  line-height: 133%;
}

.font-xxsmall-regular {
  font-size: 0.625rem;
  font-weight: normal;
  line-height: 133%;
}

.font-xxsmall-caps {
  font-size: 0.625rem;
  font-weight: bold;
  line-height: 133%;
  text-transform: uppercase;
}
