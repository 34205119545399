
.loader-rjn {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: grid;
  place-items: center;
  width: 100%;
  color: #015cbc;
  cursor: pointer;
  transition: 0.3s linear;
  z-index: 9999999;

  &:hover {
    background: transparent;
  }

  &:nth-child(2n+1) {
    background: transparent;
  }
}

.loader-72 {
  width: 100%;
  height: 4px;
  display: inline-block;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    width: 0;
    height: 4px;
    background: #015cbc;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: animFw 10s linear infinite;
    -moz-animation: animFw 10s linear infinite;
    animation: animFw 10s linear infinite;
  }
}

@-webkit-keyframes animFw {
  0% {
    width: 60%;
  }

  100% {
    width: 100%;
  }
}

@keyframes animFw {
  0% {
    width: 60%;
  }

  100% {
    width: 100%;
  }
}
