.system-card-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e2e6ea;
  max-width: 200px;
  border-radius: 5px;
  padding: 2px;
  margin-right: 5px;
  p {
    font-size: 8px;
    max-width: 85px;
    width: 100%;
    word-wrap: break-word;
  }
  span {
    font-size: 8px;
  }
  .img-wrapper {
    padding: 1%;
    span {
      font-size: 18px !important;
    }
    .drag {
      width: 11px;
    }
    .widget {
      width: 43px;
    }
    .progress {
      width: 35px;
    }
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  img {
    width: 100%;
  }
}
