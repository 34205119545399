.quiz-select {
  display: flex;
  flex-direction: column;
  width: 13.25rem;
  gap: 1rem;
  border-radius: 0.625rem;
  padding: 1rem;
  background-color: white;
  box-shadow: 0px 0px 17px 0px rgba(0, 92, 188, 0.08);
  .quiz-info {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    .quiz-label {
      font-weight: bold;
      font-size: 0.875rem;
      overflow-wrap: break-word;
    }
  }
  .tooltip-parent {
    .tooltip {
      width: 220px;
      white-space: initial;
    }
  }
  .button {
    width: 100%;
    justify-content: center;
  }
}

.quiz-select-modal .modal {
  width: 76.8%;
  max-width: 60.5rem;
  height: 39rem;
  .quiz-select-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    gap: 0.5rem;
    height: 100%;
    overflow-y: auto;
    .body-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .input-item {
        max-width: 17.0625rem;
      }
      .selected-quiz {
        font-size: 0.875rem;
      }
    }
    .loading-container {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    .quiz-list {
      .quiz-list-table {
        width: 100%;
        thead {
          th {
            text-align: left;
            padding: 14px 8px;
            background: #fbfcfe;
            box-shadow: 0px -2px 0px 0px rgba(215, 229, 248, 1) inset;
            margin-bottom: 8px;
            color: #616161;
            font-size: 0.8rem;
            text-transform: uppercase;
          }
        }
        tbody {
          background: #fbfcfe;
          td {
            background: #fbfcfe;
            box-shadow: 0px -2px 0px 0px rgba(215, 229, 248, 1) inset;
            padding: 18px 8px;
            color: #616161;
            font-size: 0.8rem;
          }
        }
      }
    }
  }
  .modal-footer {
    margin-top: auto;
  }
}
