@import "../../../styles/variables";

.pcg-list-container {
  .pcg-list-table {
    .status {
      padding: 4px 8px;
      border-radius: 7px;
      color: white;
      font-weight: normal;
      font-size: 0.8em;
      text-transform: capitalize;
      svg {
        font-size: 15px;
      }
    }

    .status.active {
      background-color: $green-primary;
    }

    .status.inactive {
      background-color: #dfdfdf;
      color: $black;
    }

    .status.imported {
      background-color: #b3dacb;
    }

    .status.expired {
      background-color: #667e88;
    }

    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    thead {
      th {
        text-align: left;
        padding: 14px 8px;
        background: $background-light-white;
        box-shadow: 0px -2px 0px 0px rgba(215, 229, 248, 1) inset;
        margin-bottom: 8px;
        color: $dark-gray;
        font-size: 0.8rem;
        text-transform: uppercase;
        &.column-status {
          width: 8%;
        }
        &.column-code-status {
          width: 20%;
        }
        &.column-title {
          width: 14%;
        }
        &.column-description {
          width: 18%;
        }
        &.column-total-codes {
          width: 10%;
        }
        &.column-exhausted-codes {
          width: 10%;
        }
        &.column-active-date {
          width: 10%;
        }
        &.column-inactive-date {
          width: 10%;
        }
        &.column-code-upload-status {
          width: 10%;
        }
        &.column-actions {
          width: 8%;
        }
      }
    }
    tbody {
      box-shadow: 0px 0px 24px 0px rgba(0, 92, 188, 0.08);
      background: $background-light-white;
      td {
        background: $background-light-white;
        box-shadow: 0px -2px 0px 0px rgba(215, 229, 248, 1) inset;
        padding: 18px 8px;
        color: $dark-gray;
        font-size: 0.8rem;
        &.no-space-title {
          max-width: 190px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &.pcg-status-type {
          justify-content: space-between;
          margin-bottom: 8px;
          &.code-status {
            svg {
              font-size: 15px;
            }
          }
          .status {
            padding: 4px 8px;
            border-radius: 7px;
            color: white;
            font-weight: normal;
            font-size: 0.8em;
            text-transform: capitalize;
          }

          .status.active {
            background-color: $green-primary;
          }

          .status.inactive {
            background-color: #dfdfdf;
            color: $black;
          }

          .status.imported {
            background-color: #b3dacb;
          }

          .status.expired {
            background-color: #667e88;
          }
        }
        .button-group-wrapper {
          .button-group {
            button {
              padding: 0px var(--spacing-spacing-0, 18px);
              font-size: 0.9rem;
              span {
                font-size: 0.9rem;
              }
            }
          }
        }
      }
    }
  }
}
