.code-group-card-container {
  padding: 0px;
  overflow: hidden;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  max-width: 350px;
}

.code-group-details {
  padding: 16px;
}

.code-group-status-type {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 16px;
  justify-content: space-between;

  .status,
  .code-upload-status {
    padding: 4px 8px;
    border-radius: 16px;
    color: white;
    background-color: rgba(223, 223, 223, 1);
    font-weight: bold;
    font-size: 0.7em;
    margin-right: 8px;
  }

  .code-upload-status.generated {
    background-color: rgb(154, 243, 154);
  }
  .code-upload-status.active {
    background-color: green;
  }
  .code-upload-status.draft {
    background-color: rgba(97, 97, 97, 1);
  }
  .code-upload-status.inactive {
    background-color: rgb(150, 143, 143);
  }
  .code-upload-status.queued {
    background-color: rgb(255, 204, 73);
  }
  .code-upload-status.in_progress {
    background-color: rgb(246, 144, 35);
  }
  .code-upload-status.expired {
    background-color: rgba(246, 204, 210, 1);
  }
}

.code-group-name {
  font-size: 1.2em;
  margin: 20px 0 20px 0;
  color: #333;
  font-size: 14px;
  max-height: 43px;
  word-break: break-all;
}

.additional-fields-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.field-pair {
  flex-basis: 50% !important;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  font-size: 12px;
}

.field-label {
  font-weight: bold;
  margin-bottom: 4px;
  font-size: 10px;
}

.field-value {
  color: #666;
  margin-top: 3px;
  font-size: 10px;
}

.code-group-dates {
  font-size: 0.9em;
  color: #333;
  display: flex;
  align-items: flex-start;
}

.date-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  font-size: 10px;
  width: 50%;
  margin-bottom: 10px;
}

.date-label {
  font-weight: bold;
  font-size: 10px;
}

.button-group-container {
  width: 100%;
  margin-top: 16px;

  .button-group {
    width: 100%;
    height: 1.6rem;
    button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      span.icon {
        font-size: 16px !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .field-pair {
    flex-basis: 100%;
  }
}
