// v2.0.5 fixes
.input-item {
  box-sizing: border-box;
  cursor: initial;

  .input {
    cursor: initial;
  }
}

.dropdown-wrapper {
  width: auto;
}

.dropdown-container {
  border: 1px solid #dfdfdf;
  z-index: 999;

  .dropdown-list {
    width: 100%;

    .list-item,
    a {
      color: var(--textSecondary);
      cursor: default;
    }
  }
}

.dropdown-input-row {
  position: relative;

  .icon-container {
    position: absolute;
    top: calc(50% - 12px);
    right: 8px;
    padding-right: 0 !important;
    height: 24px;

    .icon {
      margin-right: 0;
      cursor: pointer;

      &.disabled {
        cursor: default;
      }
    }
  }

  .dropdown-parent {
    width: 100%;
  }
}

.filter-wrapper .filter-dropdown .dropdown-input-row .dropdown-parent {
  width: calc(100% - 40px);
  position: relative;
  border: none;
  box-shadow: none;

  &:active {
    border: none;
    box-shadow: none;
  }
}

.multiple-select-wrap .label-element {
  visibility: hidden;
}
.peacock-dropdown-show-label .label-element {
  visibility: visible;
}

.button-group {
  @for $i from 0 through 10 {
    &[selectedindex="#{$i}"] > button:nth-child(#{$i + 1}) {
      background-color: var(--button-color-button-primary);
      color: $white;
    }
  }

  button {
    height: 100%;
  }
}

.button[role="button"] {
  min-width: fit-content;
}
