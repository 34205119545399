.survey-modal .modal,
.survey-modal .MuiPaper-root {
  &.MuiPaper-root {
    min-width: 900px;
    border: 10px solid var(--Colors-Blue-500, #d7e5f8);
    background: var(--layer-color-layer-01, #fbfcfe);
    .bottom-button {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      // margin-top: 30px;
    }
    .MuiSelect-select.Mui-disabled {
      background: var(--input-color-input-disabled, #d0d0d0);
      & ~ fieldset {
        border: 0px;
      }
    }
    .imageOverlay {
      min-height: 160px;
      height: 100%;
    }
    .bottom-section {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 30px;
      .toggle {
        margin-right: 20px;
        span {
          margin-right: 8px;
        }
      }
    }
  }
  width: 60%;
  padding-top: 5px;
  .survey-mod {
    max-height: 500px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    .dropdown-title {
      justify-content: space-between;
      width: 100%;
    }
    .text-input .input-wrapper input {
      width: 100%;
    }
    .dropdown-input-row .dropdown-parent {
      width: 100%;
    }
    .survey-image {
      .file-upload {
        min-width: 400px;
        max-width: 400px;
      }
    }
  }
}
.card {
  .card-header-survey {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .title {
      min-height: 43px;
      max-height: 43px;
      word-break: break-word;
      font-size: 0.875rem;
    }
    .status {
      width: fit-content;
      font-size: 10px;
      border-radius: 10px;
      font-weight: bold;
      color: white;
      padding: 4px 8px;
      &.draft {
        background-color: rgb(97, 97, 97);
      }
      &.active {
        background-color: rgb(0, 128, 0);
      }
      &.inactive {
        background-color: rgb(128, 128, 128);
      }
      &.published {
        background-color: rgb(255, 204, 73);
      }
      &.completed {
        background-color: rgb(179, 218, 203);
      }
    }
  }
  .survey-image-wrapper {
    width: 100%;
    max-width: 180px;
    height: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .survey-image {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 10px;
    object-fit: cover;
  }
  .card-info {
    display: flex;
    gap: 16px;
    justify-content: start;
    .meta-item {
      display: flex;
      flex-basis: 50% !important;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      .meta-label {
        font-weight: bold;
      }
      &.no-margin {
        margin: 0;
      }
    }
    &.no-gap {
      gap: 4%;
    }
  }

  .button-group-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 0px;
    &.deletable {
      .button-group button:last-child {
        color: #d40020;
      }
    }
  }
}

.survey-summary .modal {
  /* min-width: 40%; */
  max-height: 90%;
  max-width: 80%;
  min-width: 80%;
  .survey-summary {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .summary-container-modal {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
.section-survey {
  text-align: left;
  border-radius: 10px;
  padding: 16px;
  gap: 25px;
  box-shadow: 0px 0px 17px 0px rgba(0, 92, 188, 0.08);
  background: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
}

.section-survey h2 {
  font-size: 16px;
  font-weight: bold;
}

.cover-image-survey-summary img {
  height: auto;
}

.content-survey {
  display: flex;
  gap: 8px;
  .content-align {
    flex-direction: column;
  }
}
.sub-content-survey {
  display: flex;
  flex-direction: inline-block;
  gap: 25px;
  font-size: 12px;
  font-weight: 700;
  + span {
    font-size: 12px;
    font-weight: 400;
  }
}
.sub-content-survey-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  white-space: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5em;
  max-height: calc(1.5em * 6);
}
