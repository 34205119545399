@import "../../../styles";

.reward-modal .modal {
  width: 80%;
  height: 90%;
  max-width: 68rem;
  padding: $spacing-05;
  text-align: initial;
  .title {
    font-size: 1.125rem;
  }
  .required-fields-label {
    font-size: 0.75rem;
    width: 100%;
    margin-bottom: $spacing-06;
  }
  .loading-options {
    display: flex;
    gap: $spacing-03;
    font-size: 0.75rem;
    font-weight: 700;
  }
  .reward {
    width: 100%;
    overflow-y: auto;
    flex: 1;
    border-bottom: 2px solid $color-border-default;
    .reward-wrap {
      overflow-y: auto;
      overflow-x: clip;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: $spacing-06;
      padding-right: $spacing-03;
      padding-bottom: $spacing-04;
      .label-text .icon {
        display: none;
      }
      .reward-creation-form {
        gap: 0;
        > .vertical-layoutItem-item:nth-of-type(2) {
          margin-top: $spacing-04;
        }
        > .vertical-layoutItem-item:nth-of-type(3) {
          margin-top: $spacing-04;
        }
      }
      .dropdown-title .icon {
        display: none;
      }
      .dropdown-container {
        width: 288px;
        height: 170px;
        padding-left: $spacing-01;
        z-index: 1;
        .dropdown-list {
          width: 280px;
          .list-item .list-item-text {
            color: $color-text-secondary;
          }
          .list-header-row {
            display: none;
          }
          tr {
            height: initial;
            td {
              padding-top: $spacing-02;
              padding-bottom: $spacing-02;
            }
          }
        }
      }
      .reward-type-setup-fields {
        display: flex;
        flex-wrap: wrap;
        row-gap: $spacing-04;
        column-gap: $spacing-05;
        .horizontal-layoutItem-item {
          flex-grow: 0;
        }
        .reward-type-setup-field {
          position: relative;
        }
      }
      .reward-type-setup {
        display: flex;
        flex-direction: column;
        gap: $spacing-04;
        .reward-type-setup-label {
          font-size: 0.875rem;
        }
      }
      .no-reward-type {
        display: flex;
        flex: 1;
        gap: $spacing-04;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 27.1%;
          max-width: 16.125rem;
          height: 71.58%;
          max-height: 17rem;
        }
        h5 {
          font-size: 1.5rem;
          color: $color-text-tertiary;
        }
      }
      .reward-basic-setup {
        display: flex;
        flex-direction: column;
        gap: $spacing-06;
        .simple-form-group .title-wrapper h6 {
          font-size: 0.875rem;
        }
        .basic-setup-columns {
          .horizontal-layout {
            flex-wrap: wrap;
            margin-bottom: 0;
          }
          .reward-image-fields {
            gap: $spacing-06;
          }
          .reward-fields {
            gap: 0;
            > .vertical-layoutItem-item:nth-of-type(1) {
              .vertical-layout
                > .vertical-layoutItem-item:nth-of-type(1)
                .horizontal-layout
                > .horizontal-layoutItem-item:nth-of-type(2) {
                flex-grow: 0;
                width: 200px;
                .dropdown-title {
                  width: 200px;
                }
                .dropdown-input-row {
                  width: 200px;
                }
                .dropdown-container {
                  width: 188px;
                  .dropdown-list {
                    width: 180px;
                  }
                }
              }
              .vertical-layout > .vertical-layoutItem-item:nth-of-type(2) {
                width: 26rem;
              }
            }
            .simple-reward-specifications .simple-form-group-wrapper {
              width: 33.25rem;
            }
            .reward-specifications .simple-form-group-wrapper {
              width: 100%;
              max-width: 45.5rem;
            }
          }
          .reward-specific-fields {
            margin-top: $spacing-04;
          }
          .quantity-row > :first-child {
            flex-grow: 0;
            min-width: 13.465rem;
          }
          .input-header .required-text {
            &.float-right {
              margin-right: 0;
            }
          }
          .input-wrapper {
            .icon.clear-button {
              display: none;
            }
            input {
              margin-bottom: 0;
            }
          }
          .dropdown-title {
            .icon {
              display: none;
            }
            .dropdown-display-text.right {
              margin-right: $spacing-04;
            }
          }
          .dropdown-input-row {
            .icon-container {
              padding-right: $spacing-04;
            }
          }
          .dropdown-container {
            width: 288px;
            height: 170px;
            padding-left: $spacing-01;
            z-index: 1;
            .dropdown-list {
              width: 280px;
              .list-item .list-item-text {
                color: $color-text-secondary;
              }
              .list-header-row {
                display: none;
              }
              tr {
                height: initial;
                td {
                  padding-top: $spacing-02;
                  padding-bottom: $spacing-02;
                }
              }
            }
          }
          .link-type-sections {
            gap: 0;
            .link-type-fields {
              .vertical-layout {
                max-width: 22.25rem;
              }
              &.deep-link-fields {
                .horizontal-layoutItem-item {
                  max-width: 22.25rem;
                }
              }
            }
          }
        }
        .basic-setup-columns > .horizontal-layoutItem-item:first-of-type {
          flex: 1;
        }
        .basic-setup-columns > .horizontal-layoutItem-item:last-of-type {
          flex-grow: 4;
        }
        .partner-reward-specifications .horizontal-layoutItem-item:first-child {
          max-width: 18.75rem;
        }
        .MuiFormControl-root {
          width: 100%;
          .MuiInputBase-root {
            background-color: white;
            &.Mui-disabled {
              background-color: $color-interaction-disabled-background;
            }
          }
        }
        .specifications-loading {
          display: flex;
          justify-content: center;
          margin-top: $spacing-04;
        }
      }
    }
  }
  .toggle {
    gap: 0.5rem;
  }
  .action-buttons {
    display: inline-flex;
    width: 100%;
    margin-top: $spacing-03;
    padding-top: $spacing-03;
    justify-content: flex-end;
    gap: $spacing-04;
  }
  .modal-footer {
    display: none;
  }
}

.copy-confirmation-modal .modal {
  width: 32.25rem;
  .title {
    font-size: 1.125rem;
    margin-bottom: $spacing-01;
  }
  .action-buttons {
    display: inline-flex;
    width: 100%;
    margin-top: $spacing-03;
    padding-top: $spacing-03;
    justify-content: center;
    gap: $spacing-04;
    .button {
      height: 2rem;
    }
  }
  .modal-footer {
    display: none;
  }
}
