.social-media-card {
  .info-title {
    display: flex;
    justify-content: flex-start;
    gap: 0.5rem;
  }
  .info-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
  .info-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 1.5rem;
    height: 55px;
  }

  .avatar {
    margin-left: 1.5rem;
  }

  .settings-button {
    color: #005cbc;
    background-color: #f7fafe;
    border: 1px solid #d7e5f8;
    border-radius: 5px;
    padding: 0.3rem;

    :hover {
      cursor: pointer;
    }
  }

  .card {
    padding: 0.8rem 0.6rem;
    margin-bottom: 0.5rem;
    background-color: #fff;

    .card-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }
  }
}

.social-media-title {
  font-weight: bold;
  margin-left: 1rem;
}
