.widget-modal .modal,
.widget-modal .MuiPaper-root {
  &.MuiPaper-root {
    min-width: 90%;
    border: 10px solid var(--Colors-Blue-500, #d7e5f8);
    background: var(--layer-color-layer-01, #fbfcfe);
    .widget-mod .dropdown-input-row .icon-container .icon {
      top: unset;
      right: -93px;
    }
    .bottom-button {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      /* margin-top: 30px; */
    }
  }
  width: 100%;
  padding-top: 5px;
  .widget-mod {
    max-height: 500px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    .text-input .input-wrapper input {
      width: 100%;
    }
    .dropdown-input-row .dropdown-parent {
      width: 100%;
    }
    .dropdown-input-row .icon-container .icon {
      top: 70px;
      right: 20px;
    }
    .widgetImage {
      margin-bottom: 0.5rem;
      .file-upload {
        button {
          position: absolute;
          bottom: 7%;
          width: 20%;
          right: 0;
          left: 0;
        }
      }
    }
    h3 {
      margin-top: 10px;
      font-size: 14px;
      width: 100%;
    }
    .widget-wrap {
      padding-bottom: 10px;
      border-bottom: 1px solid grey;
      .select-wrap {
        margin-top: 10px;
        text-transform: capitalize;
        .dropdown-input-row {
          margin-right: 15px;
          width: 100%;
          input {
            width: 30.2%;
          }
        }
      }
      .grid-container {
        margin: 15px 0;
      }
      .img-wrap {
        margin-top: 10px;
        margin-right: 10px;
        .file-upload {
          width: 100%;
          .file-upload-drag-drop {
            min-height: 183px;
          }
        }
        .opt {
          font-size: 12px;
          font-weight: 700;
          float: right;
          /* margin-top: 2%; */
        }
        p {
          margin: 5px 0;
          color: var(--textSecondary);
          font-family: Inter;
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 133%;
          margin-bottom: 0.5rem;
        }
        button {
          margin: 5px 0;
          width: 100%;
          justify-content: center;
          padding: 0px 0 0 13%;
        }
        img {
          width: 100%;
          height: 172px;
        }
      }
      .widget-type {
        margin-right: 12px;
        margin-top: 5px;
      }
      .widget-quantity {
        margin-right: 12px;
      }
      .unit {
        .dropdown-input-row {
          /* margin-right: 15px; */
          width: 100%;
          input {
            width: 14.5%;
          }
        }
      }
      .description {
        margin-top: 15px;
        .text-area-editor-wrapper {
          min-height: 80px;
        }
        .text-area {
          width: 100%;
          .text-area-toolbar {
            display: none;
          }
        }
      }
      .to {
        padding: 0 10px;
        font-size: 13px;
      }
      .input-label-wrap {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        position: relative;
        .input-item {
          width: 100%;
        }
        .req {
          font-size: 12px;
          font-weight: 700;
          float: right;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
      .label {
        color: var(--textSecondary);
        font-family: Inter;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 133%;
        margin-bottom: 0.5rem;
      }
      .dropdown-input-row .dropdown-parent {
        width: 100%;
      }
      .dropdown-input-row .icon-container .icon {
        top: 70px;
        right: 20px;
      }
      .text-area {
        width: 100%;
        .text-area-toolbar {
          display: none;
        }
      }
      .color-box-wrap {
        margin: 0 3%;
        .color-box {
          width: 25px;
          height: 25px;
          border-radius: 3px;
          background-color: #aad2e2;
          margin: 0 2%;
          margin-top: 20px;
        }
      }
      .remove-all {
        text-align: right;
        padding-right: 15px;
      }
    }
  }
  .toggle.bottom {
    position: absolute;
    bottom: 44px;
    right: 228px;
  }
  .text-input-hexa-color-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .input-squad-color {
      margin-bottom: 2px;
    }
  }
  .error-msg {
    color: red;
    text-align: center;
  }
  .MuiSelect-select.Mui-disabled {
    background: var(--input-color-input-disabled, #d0d0d0);
    & ~ fieldset {
      border: 0px;
    }
  }
  .imageOverlay {
    min-height: 160px;
    height: unset;
  }
  .bottom-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    .toggle {
      margin-right: 20px;
      span {
        margin-right: 8px;
      }
    }
  }
}

.widget-modal {
  .widgetImage {
    display: block;
  }
  .MuiSelect-select {
    text-transform: capitalize;
  }
  .dragdrop-container {
    border: 1px solid #d7e5f8;
    padding: 2%;
    .item {
      margin-right: 10px;
    }
  }
  .hideLabel {
    .input-header {
      display: none;
      height: 0;
    }
  }
  .marginTop {
    margin-top: -21.5px;
  }
  .toggleStyles {
    margin-bottom: -5px;
  }
  .bottom-button {
    margin-top: 2%;
  }
}
