.radio-help-text {
  display: flex;
  flex-direction: row;
  color: var(--textSecondary);
  gap: 0.25rem;
  margin-left: 0.25rem;
  .tooltip {
    width: 21.75rem;
    white-space: pre-line;
  }
}
