//variables
@import '../../../styles/variables';

.textinput-wrapper {
  align-items: center;
  position: relative;
  .input-wrapper {
    input {
      margin-right: 0;
    }
  }
  .tooltip-wrapper {
    margin-bottom: 7px;
    display: flex;
    width: 100%;
    span {
      font-size: 12px;
      color: $dark-gray;
    }
    .text-input {
      display: inline-block;

    }

    .icon {
      margin-left: 5px;
      font-size: 18px !important;
      color: $almost-black;
    }
  }
}
