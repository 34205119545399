@import "../../../styles";

.multilanguage-content {
  display: flex;
  flex-direction: column;
  background-color: $color-background-layer-02;
  padding: $spacing-04;
  border-radius: $spacing-03;
  gap: $spacing-02;
  .language-item {
    align-items: center;
    width: 100%;
    border-radius: 0.5rem;
    padding: $spacing-04 $spacing-03;
    gap: $spacing-04;
    border: 1px solid $color-border-default;
    &.language-added {
      background-color: $color-background-layer-01;
    }
    .added-language {
      width: 100%;
      .language-content {
        gap: $spacing-04;
        .language-image {
          width: 5.25rem;
          height: 2.8rem;
          border-radius: 0.5rem;
          object-fit: cover;
        }
      }
    }
    .button-group button {
      justify-content: center;
      width: 2rem;
      height: 2rem;
      padding: $spacing-03;
      border-radius: 0.25rem;
    }
    .button-group-small {
      height: fit-content;
    }
  }
}
.content-modal {
  .modal {
    max-width: 700px;
    max-height: 80%;
    padding: $spacing-04;
  }
}

.campaign-language-content-modal {
  .modal {
    width: 75%;
    padding: $spacing-05;
    max-height: 87%;
    div {
      width: 100%;
    }
    .dropdown-input-row > div {
      width: unset;
    }

    .title {
      font-size: 1.125rem;
    }
    .dropdown-input-row {
      position: relative;
    }
    .required-fields {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      font-weight: 700;
      font-size: 0.75rem;
      margin-bottom: $spacing-04;
    }
    .input-wrapper {
      input {
        margin-right: 0;
        width: 100%;
      }
      .clear-button {
        display: none;
      }
    }
    .input-header .required-text {
      margin-right: 0;
    }
    .text-area .input-header .label-text .icon {
      display: none;
    }
    .multiple-select-wrap {
      .icon-container {
        position: absolute;
        gap: $spacing-01;
        right: 0.5rem;
        top: 50%;
        transform: translateY(-50%);
        width: auto;
      }
    }
  }
}
