.custom-modal-partner-form {
  .modal {
    .toggleStyles {
      position: absolute;
      bottom: -2.85rem;
      right: 9rem;
    }
  }

  @media (max-width: 992px) {
    .modal {
      width: 90%;
    }
  }
}
