.input-help-text {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  color: var(--textSecondary);
  gap: 0.25rem;
  font-size: 0.75rem;
  .tooltip {
    width: 17.3125rem;
    white-space: pre-line;
  }
}
.input-text-wrapper {
  position: relative;
  .tooltip-text {
    position: absolute;
    top: 0;
    right: 0;
  }
  .tooltip {
    min-width: 300px;
    white-space: initial;
  }
  .hideLabel {
    margin-top: 3.5px;
    &.half-width {
      .input-wrapper {
        width: 48% !important;
      }
    }
    .label-element,
    label {
      display: none;
    }
  }
}
.disable-button .clear-button {
  display: none;
}
