@import "../../../styles/peacock-tokens.scss";

.input-header {
  margin-bottom: 0.5rem;
  color: $color-text-secondary;
}

.toolbar-text-editor {
  display: flex;
  justify-content: flex-end;
}

.toolbar-text-editor-disabled {
  .ql-editor {
    background-color: $color-interaction-disabled-background;
    color: $color-interaction-disabled;
  }

  .ql-toolbar {
    background-color: $color-interaction-disabled-background;
    color: $color-interaction-disabled;
  }
}
