.card-container {
  // padding: 0px;
  display: flex;
  flex-direction: column;
  color: #3a3a3a;
height: 100%;
  // .card-header {
  position: relative;
  //   display: none;
  // }

  .card-content {
    min-height: 100px;
  }
  .details {
    padding: 16px;
  }

  .name {
    font-size: 12px;
    margin: 15px 0 10px 0;
    // height: 37px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .description {
    font-size: 12px;
    margin-bottom: 10px;
    display: flex; 
    justify-content: space-between; 
  }

  .meta-item {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: flex-start; /* Center-align items */
    margin-right: 1em;
  }

  .meta-item span {
    display: block; /* Make each span a block element */
  }

  .meta-label {
    font-weight: bold; /* Make label text bold */
  }

  .reward-meta span {
    display: block;
    margin: 4px 0;
  }

  .button-group-container {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // width: 100%;
    // margin-top: 16px;
  bottom: 0;
  width: 100%;
  padding: 10px;

    .button-group {
      button {
        padding: 0px var(--spacing-spacing-0, 5%);
      }
    }
  }
}
