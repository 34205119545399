.segment-card-container {
  padding: 0px;
  display: flex;
  flex-direction: column;
  color: #3a3a3a;

  .card-header {
    display: none;
  }

  .segment-details {
    padding: 16px;
  }

  .segment-status-type {
    display: flex;
    gap: 16px;
    justify-content: flex-start;
    margin-bottom: 8px;
  }

  .status {
    padding: 3px 8px;
    border-radius: 7px;
    color: white;
    font-weight: normal;
    font-size: 0.8em;
  }

  .status.active {
    background-color: #006a42;
  }

  .status.inactive {
    background-color: #eee;
    color: #000;
  }

  .type {
    background-color: rgba(170, 210, 226, 1);
    border-radius: 16px;
    padding: 4px 8px;
    font-size: 0.8em;
    color: #3a3a3a; /* Optional: Customize text color for better contrast */
  }

  .segment-name {
    font-size: 14px;
    margin: 15px 0 10px 0;
    height: 37px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .segment-meta {
    font-size: 0.7em;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .meta-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-right: 1em;
  }

  .meta-item:last-child {
    margin-right: 0;
    width: 28%;
  }

  .meta-item span {
    display: block;
  }

  .meta-label {
    font-weight: bold;
  }

  .button-group-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 16px;

    .button-group {
      button {
        padding: 0px var(--spacing-spacing-0, 5%);
      }
    }
  }
}
