@import "../../../styles";

.icon-button-group > button {
  padding: $spacing-03;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.outcome-form-container {
  border-radius: 8px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-04 $spacing-04 0 $spacing-04;
}

.rules-container {
  padding: $spacing-04;
  border-radius: 8px;
  border: 1px solid $color-border-default;
  background: #fff;
  margin: $spacing-04 0;
  position: relative;
}

.model-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
}

.outcome-rule-padding {
  padding-bottom: $spacing-04;
}

.custom-width {
  width: 100%;
  margin-bottom: $spacing-03;
}

.outcome-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.sweepstake-outcome-container {
  padding-top: 8px;
  border-radius: 8px;
  position: relative;
}

.activity-rule-setup-modal-paper {
  min-width: 75% !important;
  border: 10px solid var(--Colors-Blue-500, #d7e5f8);
  background: var(--layer-color-layer-01, #fbfcfe);
  min-height: 70%;
  max-width: 950px;
  .MuiDialogContent-root {
    padding: 1rem;
  }
  .content-body {
    padding-left: 10px;
  }
  .bottom-button {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin: 1rem;
    gap: 0.5rem;
  }

  padding-top: 5px;
}
