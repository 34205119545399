.code-search-container {
  display: block !important;
  background: #fff;
  padding: 40px;
  border-radius: 5px;
  margin: 20px;

  .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .search-input {
    display: block;
  }

  .search-result {
    margin-top: 20px;
  }
}
