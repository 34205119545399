/* RangeFilter.css */
.range-filter-popover {
  border-radius: 8px;
  .icon-container {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    gap: 16px;
    .icon-button {
      padding: 4px;
    }

    .apply-icon {
      color: green;
      font-size: 24px;
    }

    .clear-icon {
      color: red;
      font-size: 24px;
    }
  }
}

.popover-content {
  padding: 16px;
  width: 220px;
  .input-container {
    display: flex;
    gap: 8px;
  }
}
