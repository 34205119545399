.appConfigurationsForm {
  .horizontal-layoutItem-item {
    flex-basis: unset;
    flex-grow: unset;
    max-width: 100%;
    position: relative;
    width: 333px;
    .my-1 {
      margin-top: 0rem;
    }
  }

  .dropdown-input-row {
    width: 315px;
  }

  .dropdown-title {
    display: inline-flex;
    width: 333px;
    vertical-align: middle;
    column-gap: 3px;
  }

  .dropdown-container {
    a {
      color: unset;
    }
  }
}

.app-configurations-cards-container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 0 15px 0 15px;
  .app-config-card {
    background-color: #eff5fc;
    display: flex;
    flex-direction: row;
    padding: 20px;
    row-gap: 10px;
    width: 100%;
    border-radius: 5px;
  }
  .values-container {
    display: flex;
    flex-direction: row;
  }
  h3 {
    font-weight: bold;
    font-size: 14px;
    width: 100%;
  }
  h4 {
    font-weight: bold;
    font-size: 12px;
    width: 30%;
  }
  h5 {
    font-weight: bold;
    font-size: 12px;
    width: 50%;
  }
  h6 {
    font-weight: bold;
    font-size: 12px;
    width: 33.3%;
  }
  span {
    font-weight: normal;
  }
}
