.details-section {
  display: flex;
  flex-direction: column;
  border-radius: 0.625rem;
  padding: 1rem;
  gap: 1rem;
  background-color: rgb(239, 245, 252);
  .details-image-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .details-image {
      width: 13.75rem;
      height: 7.5rem;
      border-radius: 0.5rem;
      object-fit: cover;
    }
  }
  .details-section-title {
    font-size: 1rem;
    font-weight: bold;
    &.subtitle {
      font-size: 0.875rem;
    }
  }
  .details-section-label {
    font-size: 0.75rem;
    font-weight: bold;
    color: rgb(58, 58, 58);
  }
  .details-section-value {
    display: flex;
    column-gap: 1rem;
    row-gap: 0.5rem;
    font-size: 0.75rem;
    .content-page-text-details {
      white-space: pre-wrap;
    }
  }
  .details-section-row {
    display: flex;
    column-gap: 2.5rem;
    row-gap: 1.5rem;
    flex-wrap: wrap;
  }
  .details-section-column {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}
