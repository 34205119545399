.codegroups-table {
  display: inline-block;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    thead {
      border-bottom: 3px solid #d7e5f8;

      tr {
        th {
          text-align: left;
          padding: 14px 5px;
          margin: 0;
          margin-bottom: 8px;
          color: #616161;
          font-size: 11px;
          text-transform: uppercase;
          width: auto;

          // &.column-checkbox {
          //   width: 4%;
          // }

          // &.column-brands {
          //   width: 14%;
          // }

          // &.column-kewords {
          //   width: 80%;
          // }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 2px solid #d7e5f8;
      }

      td {
        margin: 0;
        padding: 14px 8px;
        color: #616161;
        font-size: 0.8rem;
      }
    }
  }
}

.code-group-ending-early {
  background-color: #ffbf1c !important;
}
