//variables
@import "../../styles/variables";

.container {
  padding: 16px, 16px, 16px, 0px;
  gap: 16px;
  .wrap {
    .required-chip {
      background-color: #aad2e2;
      width: 150px;
    }
    .permissions-container {
      padding-top: 16px;
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }
    .permission-item {
      flex: 0 1 calc(50% - 16px);
      border: 1px solid #ccc;
      padding: 16px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .title {
      color: #3a3a3a;
      font-size: 12px;
      font-weight: 700;
      margin: 0;
      margin-right: 10px;
    }
    .content {
      color: #3a3a3a;
      font-size: 12px;
      margin-right: 20px;
    }
    .permission-actions {
      display: flex;
      gap: 8px;
      margin-top: 8px;
    }

    .button-container {
      margin-top: auto;
      display: flex;
      justify-content: flex-end;
      padding: 0px;
    }
    .custom-hr {
      border: none;
      border-top: 1px solid #d7e5f8;
    }
    margin-top: 15px;
    background: $white;
    padding: 16px;
    gap: 16px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    .heading-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 15px;
      h2 {
        margin-left: 16px;
        width: 50%;
      }
    }
    .filter-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      width: 100%;

      .filter-container-search {
        width: 30%;
        padding-top: 20px;
      }
      .input-search {
        background-color: $white;
        border-left: 0;
        border-right: 0;
        border-top: 0;
        border-radius: 0;
        input {
          font-size: 0.9rem;
        }
      }
    }
    .card-list {
      padding: 8px;
      padding-top: 15px;
      height: calc(100vh - 295px);
      overflow-y: auto;
      .card {
        min-height: 180px;
      }
    }
  }
}

.empty-state-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.empty-state {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(91.5vh - 13.6rem);
  img {
    width: 14.125rem;
    height: 14.8125rem;
  }
  p {
    font-size: 2rem;
    font-weight: 700;
    color: rgb(160, 160, 160);
  }
}

.user-limit-settings-form {
  margin-left: 16px;
  width: 80%;
}

.app-configurations-form {
  margin-left: 16px;
  width: 100%;
}
