@import "../../styles/peacock-tokens";

.image-uploader {
  .file-upload {
    width: 100%;
    min-width: 250px;
  }
}

.image-picker .image-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  width: 80%;

  // Pepsico-ds overrides
  .file-upload {
    width: 100%;

    .file-upload-drag-drop > div {
      width: unset;
    }
  }
}

.image-picker-wrapper {
  position: fixed;
  width: calc(100% + 128px) !important;
  height: calc(100% + 80px);
  max-width: calc(100vw - 32px);
  max-height: calc(100vh - 32px);
  min-width: 80vw;
  min-height: 60vh;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  right: 0;
  bottom: 0;
  z-index: 3333;
  box-sizing: border-box;
  border-radius: 8px;
  border: 10px solid #d7e5f8;
  background-color: #fbfcfe;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .image-uploader-file-wrapper {
    width: calc(100% - 48px) !important;
    max-width: 640px;

    .error {
      @extend .font-small-regular;
      color: $color-feedback-error;
      margin: 16px 0;
    }

    .file-upload {
      width: 100%;
      height: 100%;

      .file-container {
        min-height: 15rem;
      }
    }
  }

  &__title {
    padding: 1rem;
    display: flex;
    justify-content: center;
    font-size: 1.125rem;
    color: #3a3a3a;
  }
}

.image-picker {
  width: 100%;
  height: 100%;
  height: calc(100% - 7.5rem);

  &__loader,
  &__error,
  &__empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(auto-fill, 180px);
    grid-gap: 8px;
    align-items: start;
    justify-content: space-between;
    justify-items: center;
    padding: 1rem;

    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  &__thumbnail {
    cursor: pointer;
    width: 100%;
    box-shadow: 0px 0px 0px 1px #d7e5f8;
    border-radius: 4px;
    padding: 8px;
    margin: 8px 0;
    background-color: rgba(215, 229, 248, 0.3);
    border: 1px solid transparent;
    transition:
      border-color 0.3s,
      background-color 0.3s;

    &:hover {
      background-color: #d7e5f8;
    }

    &.selected {
      background-color: #d7e5f8;
      border-color: #005cbc;
    }
  }

  &__image {
    width: 100%;
    height: 120px;
    object-fit: contain;
    background-color: #fff;
  }

  &__label {
    display: block;
    word-break: break-all;
    font-size: 11px;
    line-height: 1.3em;
    padding-top: 4px;
  }

  &__actions {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1rem;

    > div {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 1rem;
    }
  }
}
