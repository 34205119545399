@import "../../../../styles/variables";

.segement-detail-card {
  padding: 24px;
  background: $segment-detail-card-background;
  border-radius: 10px;
  margin: 12px;
  h3 {
    margin-bottom: 16px;
  }
}

.tabs-wrap {
  display: flex;
  justify-content: flex-start;
  gap: 24px;
  width: 60%;
  .tab {
    flex: 1;
    text-align: center;
    box-sizing: border-box;
  }
}

.segment-summary-table {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  thead {
    th {
      padding: 14px 8px;
      text-transform: uppercase;
      background: $background-light-white;
      margin-bottom: 8px;
      color: $dark-gray;
      font-size: 0.8rem;
      text-align: left;
      border-bottom: 2px solid $secondary-color;
    }
  }
  tbody {
    background: $background-light-white;
    td {
      padding: 14px 8px;
      color: $dark-gray;
      font-size: 0.8rem;
      text-align: left;
      border-bottom: 2px solid $secondary-color;
    }
  }
}
