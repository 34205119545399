.toast-notification {
  .MuiPaper-root {
    justify-content: center;
    width: 100%;
    color: initial;
    background-color: initial;
    box-shadow: none;
    .MuiSnackbarContent-message {
      width: 100%;
      .notification {
        width: 100%;
      }
    }
  }
}
