.date-picker-material-ui-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  label {
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    color: var(--text-color-text-secondary, #616161);
  }
}
.customDatePickerStyles {
  label.MuiInputLabel-root {
    font-size: 13px;
    top: -7px;
  }
  fieldset {
    border: 1px solid #c0c0c0 !important;
  }
}
