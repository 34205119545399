.partner-code-group-card-container {
  padding: 0px;
  overflow: hidden;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  max-width: 350px; /* Adjust width as needed */
}

.partner-code-group-details {
  padding: 16px;
}

.partner-code-group-status-type {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  .status,
  .code-upload-status {
    padding: 4px 8px;
    border-radius: 16px;
    color: #3a3a3a;
    background-color: #b3dacb;
    font-weight: bold;
    font-size: 0.7em;
    margin-right: 8px; /* Space between status fields */
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 15px;
    }
  }

  .status.active {
    color: #fff;
    background-color: #006a42;
  }

  .status.inactive {
    background-color: #dfdfdf;
  }

  .status.imported {
    background-color: #b3dacb;
  }

  .status.expired {
    background-color: #667e88;
  }

  .code-upload-status.code-imported {
    background-color: green;
  }

  .code-upload-status.pending {
    background-color: rgb(255, 204, 73);
  }
}

.partner-code-group-name {
  font-size: 1.2em;
  margin: 20px 0 20px 0;
  color: #333;
  font-size: 14px;
  max-height: 43px;
  word-break: break-all;
}

.additional-fields-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.field-pair {
  flex-basis: 50% !important; /* Each pair takes up half the width of the container */
  display: flex;
  flex-direction: column; /* Stack label and value vertically */
  margin-bottom: 8px; /* Space between rows */
  font-size: 12px;
}

.field-label {
  font-weight: bold;
  margin-bottom: 4px; /* Small space between the label and the value */
  font-size: 10px;
}

.field-value {
  color: #666; /* Gray color for the value, consistent with other metadata */
  margin-top: 3px;
  font-size: 10px;
}

.partner-code-group-dates {
  font-size: 0.9em;
  color: #333;
  display: flex; /* Enable flex layout */
  align-items: flex-start; /* Align items to the start of the flex direction */
}

.date-item {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: flex-start; /* Align items to the start */
  text-align: left; /* Text aligned to the left */
  gap: 4px;
  font-size: 10px;
  width: 50%;
  margin-bottom: 10px;
}

.date-label {
  font-weight: bold; /* Make label text bold */
  font-size: 12px;
  & ~ span {
    margin-top: 3px;
  }
}

.button-group-container {
  width: 100%;
  margin-top: 16px;
  .button-group {
    width: 100%;
    height: 1.6rem;
    button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px var(--Spacing-spacing-04, 5px);
      span.icon {
        font-size: 16px !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .field-pair {
    flex-basis: 100%; /* Stack fields vertically on smaller screens */
  }
}
