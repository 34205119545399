.fileUploader .file-upload-header {
  display: none;
}

.fileUploader .file-upload-drag-drop {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fileUploadHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.images-container {
  position: relative;
  height: 200px;
  min-width: 250px; // Same as the min-width of the ImagePicker
}

.images-container:hover .imageOverlay {
  opacity: 1 !important;
  display: flex !important;
}

.imageOverlay {
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background: #272727cc;
  display: none;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  &.loader {
    display: flex;
    opacity: 1;
  }
}

.partner-code-group-file-uploader {
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  flex-direction: column;

  .file-icon {
    color: #3696d0;
  }

  .file-icon-large {
    font-size: 36px;
  }

  .uploader-view {
    flex: 1;
    background: #eff5fd;
    border-radius: 8px;
    padding: 20px;
    min-height: 120px;
    border: 1px solid #eee;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    height: 100%;
    width: 100%;
    font-size: 12px;
  }

  .file-list {
    width: 100%;
  }

  .file-uploader__file {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background: #eff5fe;
    border-radius: 8px;
    margin: 4px;
    padding: 8px 10px;
    font-size: 12px;
    align-items: center;

    .file-uploader__file-name {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    button {
      background: none;
      border: none;
      cursor: pointer;
      color: #1f5cbc;
    }

    button:disabled {
      cursor: not-allowed;
      color: #ccc;
    }
  }

  input[type="file"] {
    display: none;
  }
}
