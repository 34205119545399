.toggleIcon {
    color: #005CBC !important;
}

.filter-type-setup-label {
    .MuiTypography-root {
        font-size: 0.875rem;
        font-weight: bold;
    }
}

.filter-type-setup-fields {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 1.5rem;

    .filter-type-setup-field {
        position: relative;
    }
}