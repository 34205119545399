//variables
@import "../../styles/variables";

.codegroup-selection-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(243, 247, 253, 1);
  border-radius: 8px;
  padding: 8px 16px;
  margin-bottom: 16px;

  .codegroup-selected-count {
    font-weight: 700;
    font-size: 12px;
  }

  .codegroup-selection-actions {
    display: flex;
    gap: 16px;
  }
}
div[role="dialog"] {
  z-index: 3334;
}
.codegroup-list-container {
  .codegroup-list-table {
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    thead {
      th {
        text-align: left;
        padding: 14px 8px;
        background: $background-light-white;
        box-shadow: 0px -2px 0px 0px rgba(215, 229, 248, 1) inset;
        margin-bottom: 8px;
        color: $dark-gray;
        font-size: 0.8rem;
        text-transform: uppercase;
        &.codegroup-column-status {
          width: 11%;
        }
        &.codegroup-column-title {
          width: 25%;
        }
        &.codegroup-column-description {
          width: 10%;
        }
        &.codegroup-column-total-codes {
          width: 10%;
        }
        &.codegroup-column-active-date {
          width: 15%;
        }
        &.codegroup-column-actions {
          width: 18%;
        }
      }
    }
    tbody {
      box-shadow: 0px 0px 24px 0px rgba(0, 92, 188, 0.08);
      background: $background-light-white;
      td {
        background: $background-light-white;
        box-shadow: 0px -2px 0px 0px rgba(215, 229, 248, 1) inset;
        padding: 18px 8px;
        color: $dark-gray;
        font-size: 0.8rem;
        &.codegroup-no-space-title {
          max-width: 190px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &.codegroup-widget-status-type {
          justify-content: space-between;
          margin-bottom: 8px;
          .codegroup-card-tags {
            display: flex;
            gap: 0.25rem;
          }
          .codegroup-status {
            padding: 4px 8px;
            border-radius: 7px;
            color: white;
            font-weight: normal;
            font-size: 0.8em;
          }

          .codegroup-status.enabled {
            background-color: $green-primary;
          }

          .codegroup-status.disabled {
            background-color: rgb(225, 218, 218);
            color: #000000;
          }
        }
        .codegroup-lifecycle-status {
          padding: 4px 8px;
          border-radius: 7px;
          font-weight: normal;
          font-size: 0.8em;
          &.generated {
            background-color: rgb(154, 243, 154);
            color: #fff;
          }
          &.active {
            background-color: green;
            color: #fff;
          }
          &.inactive {
            background-color: rgb(150, 143, 143);
            color: #fff;
          }
          &.draft {
            background-color: rgba(97, 97, 97, 1);
            color: #fff;
          }
          &.queued {
            background-color: rgb(255, 204, 73);
            color: #fff;
          }
          &.in_progress {
            background-color: rgb(246, 144, 35);
            color: #fff;
          }
          &.expired {
            background-color: rgba(246, 204, 210, 1);
            color: #000;
          }
          &.default {
            background-color: #eee;
            color: #000;
          }
        }
        .codegroup-button-group-container {
          .codegroup-button-group {
            button {
              padding: 0px var(--spacing-spacing-0, 18px);
              font-size: 0.9rem;
              span {
                font-size: 0.9rem;
              }
            }
          }
        }

        .codegroup-inactive {
          .codegroup-button-group {
            button:last-child {
              color: $red-primary;
            }
          }
        }
      }
    }
  }
}
