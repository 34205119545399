//variables
@import '../../../styles/variables';

.modal-container.modal-confirm {
    .modal {
        color: $almost-black;
        min-width: 530px;
        .title {
            font-size: 18px;
            margin-bottom: 0;
        }
        .detail-text {
            margin-top: 5px;
        }
        .modal-footer {
            margin-top: 15px;
            padding-top: 0;
            span:first-child {
                display: none;
            }
            span {
                width: 100%;
                display: flex;
                justify-content: center;
                gap: 1px;
            }
        }
    }
}