.main-container {
  background-color: #d7e5f8;
  border-radius: 10px;
  width: 100%;
  padding: 1rem;
}

.title-description-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.custom-modal {
  .modal-footer {
    display: none;
  }
}
