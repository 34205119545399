/* horizontal layout styles */
.horizontal-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
}

.gap-normal {
  gap: 0.8rem;
}

.gap-simple {
  gap: 0.5rem;
}

.horizontal-layoutItem-item {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
}

/* vertical layout styles */
.vertical-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.vertical-layoutItem-item {
  flex: 1;
  max-width: 100%;
  position: relative;
}

.sweepstake-custom-styles {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 8px;
}
