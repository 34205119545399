.create-modal-wrapper {
  .modal-container {
    gap: 1.25rem;
    .inputType-number .input-wrapper .clear-button {
      display: none;
    }

    .basic-setup {
      flex: 5;
    }

    .dummy-codes-container {
      width: 50%;
    }
    .code-specifications-wrapper {
      flex: 2;
      max-width: 28.5%;
      .simple-form-group-wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
    }
  }
}
