.user-limit-cards-container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  .user-limit-card {
    background-color: #eff5fc;
    display: flex;
    flex-direction: column;
    padding: 20px;
    row-gap: 10px;
    width: 100%;
    border-radius: 5px;
  }
  .values-container {
    display: flex;
    flex-direction: row;
    column-gap: 100px;
  }
  .description {
    font-size: 12px;
    line-height: 1.5;
    margin-bottom: 8px;
    color: #616161;
  }
  h5 {
    font-weight: bold;
    font-size: 12px;
    width: 30%;
  }
  span {
    font-weight: normal;
  }
}
