.text-input-hexa-color-wrapper {
    .input-squad-color {
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;
        border: 1px solid #cecece;
    }
    .text-input {
        display: inline-block;
        width: 128px;
        input {
            margin-right: 0;
            width: 100%;
            text-transform: uppercase;
        }
        span.label-text {
            font-size: 12px;
            span.icon {
                display: none;
            }
        }
    }
}
