.tag-select {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
  .button-group button {
    border-radius: 0.25rem;
  }
}

.create-tag-modal .modal {
  width: 492px;
  .title {
    font-size: 18px;
  }
  .input-item {
    width: 100%;
  }
  .action-buttons {
    display: inline-flex;
    width: 100%;
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    justify-content: flex-end;
  }
  .modal-footer {
    display: none;
  }
}
