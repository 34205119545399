.status {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  width: fit-content;
  font-size: 10px;
  border-radius: 4px;
  padding: 2px 4px;
  font-weight: normal;
  line-height: 140%;
  &.draft {
    background-color: rgb(97, 97, 97);
  }
  &.scheduled {
    background-color: rgb(255, 204, 73);
    color: rgb(58, 58, 58);
  }
  &.completed {
    background-color: rgb(102, 126, 136);
    color: white;
  }
  &.ended {
    background-color: rgb(179, 218, 203);
    color: rgb(7, 3, 3);
  }
  &.none {
    background-color: rgb(97, 97, 97);
  }
  &.not {
    &.started {
      background-color: rgb(97, 97, 97);
    }
  }
}
