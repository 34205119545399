@import "../../../../styles";

.table-list-checkbox {
  display: inline-block;
  width: 100%;
  height: 400px;
  overflow-y: scroll;
  border-spacing: 0;
  border-collapse: separate;

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    thead {
      border-bottom: 3px solid $color-border-default;

      tr {
        th {
          text-align: left;
          padding: $spacing-04 $spacing-02;
          margin: 0;
          margin-bottom: $spacing-03;
          color: $color-text-secondary;
          font-size: 11px;
          text-transform: uppercase;

          &.column-checkbox {
            width: 4%;
          }

          &.column-brands {
            width: 14%;
          }

          &.column-kewords {
            width: 80%;
          }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 2px solid $color-border-default;
      }

      td {
        margin: 0;
        padding: $spacing-04 $spacing-03;
        color: $color-text-secondary;
        font-size: 0.8rem;
      }
    }
  }
}
