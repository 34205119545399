.display-block {
  display: block;
}

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}

.display-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.display-inline-flex {
  display: inline-flex;
}

.display-grid {
  display: grid;
}

.display-inline-grid {
  display: inline-grid;
}

.display-none {
  display: none;
}

.display-table {
  display: table;
}

.display-table-row {
  display: table-row;
}

.display-table-cell {
  display: table-cell;
}

// Flexbox classes

.flex-direction-column {
  flex-direction: column;
}

// Justify content classes
.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-around {
  justify-content: space-around;
}

// Align items classes
.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.gap-0 {
  gap: 0.1rem;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

// Grid classes
.grid {
  display: grid;
}

// Grid row classes
.grid-row {
  grid-template-columns: auto;
}

.grid-row-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-row-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-row-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

// Grid column classes
.col-span-2 {
  grid-column: span 2;
}

.col-span-3 {
  grid-column: span 3;
}

.col-span-4 {
  grid-column: span 4;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-start-4 {
  grid-column-start: 4;
}

.col-end-2 {
  grid-column-end: 2;
}

.col-end-3 {
  grid-column-end: 3;
}

.col-end-4 {
  grid-column-end: 4;
}

// Row class
.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  /* Adjust as needed */
  margin-right: -15px;
  /* Adjust as needed */
}

// Column classes
.col {
  flex: 1;
  padding-left: 15px;
  /* Adjust as needed */
  padding-right: 15px;
  /* Adjust as needed */
}

// Column widths
.col-1 {
  flex-basis: 8.33333333%;
}

/* 1/12 */
.col-2 {
  flex-basis: 16.66666667%;
}

/* 2/12 */
.col-3 {
  flex-basis: 25%;
}

/* 3/12 */
.col-4 {
  flex-basis: 33.33333333%;
}

/* 4/12 */
.col-5 {
  flex-basis: 41.66666667%;
}

/* 5/12 */
.col-6 {
  flex-basis: 50%;
}

/* 6/12 */
.col-7 {
  flex-basis: 58.33333333%;
}

/* 7/12 */
.col-8 {
  flex-basis: 66.66666667%;
}

/* 8/12 */
.col-9 {
  flex-basis: 75%;
}

/* 9/12 */
.col-10 {
  flex-basis: 83.33333333%;
}

/* 10/12 */
.col-11 {
  flex-basis: 91.66666667%;
}

/* 11/12 */
.col-12 {
  flex-basis: 100%;
}

/* 12/12 */

// Offset columns
.offset-1 {
  margin-left: 8.33333333%;
}

/* 1/12 */
.offset-2 {
  margin-left: 16.66666667%;
}

/* 2/12 */
.offset-3 {
  margin-left: 25%;
}

/* 3/12 */
.offset-4 {
  margin-left: 33.33333333%;
}

/* 4/12 */
.offset-5 {
  margin-left: 41.66666667%;
}

/* 5/12 */
.offset-6 {
  margin-left: 50%;
}

/* 6/12 */
.offset-7 {
  margin-left: 58.33333333%;
}

/* 7/12 */
.offset-8 {
  margin-left: 66.66666667%;
}

/* 8/12 */
.offset-9 {
  margin-left: 75%;
}

/* 9/12 */
.offset-10 {
  margin-left: 83.33333333%;
}

/* 10/12 */
.offset-11 {
  margin-left: 91.66666667%;
}

/* 11/12 */

// Add gaps and gutters
.row {
  margin-left: -15px;
  /* Adjust as needed */
  margin-right: -15px;
  /* Adjust as needed */
}

.col {
  padding-left: 15px;
  /* Adjust as needed */
  padding-right: 15px;
  /* Adjust as needed */
}

// Gap between columns
.row-gap {
  margin-left: -15px;
  /* Adjust as needed */
  margin-right: -15px;
  /* Adjust as needed */
}

.col-gap {
  padding-left: 15px;
  /* Adjust as needed */
  padding-right: 15px;
  /* Adjust as needed */
}

// Column widths with gap
.col-1-gap {
  flex-basis: calc(8.33333333% - 30px);
}

/* 1/12 */
.col-2-gap {
  flex-basis: calc(16.66666667% - 30px);
}

/* 2/12 */
.col-3-gap {
  flex-basis: calc(25% - 30px);
}

/* 3/12 */
.col-4-gap {
  flex-basis: calc(33.33333333% - 30px);
}

/* 4/12 */
.col-5-gap {
  flex-basis: calc(41.66666667% - 30px);
}

/* 5/12 */
.col-6-gap {
  flex-basis: calc(50% - 30px);
}

/* 6/12 */
.col-7-gap {
  flex-basis: calc(58.33333333% - 30px);
}

/* 7/12 */
.col-8-gap {
  flex-basis: calc(66.66666667% - 30px);
}

/* 8/12 */
.col-9-gap {
  flex-basis: calc(75% - 30px);
}

/* 9/12 */
.col-10-gap {
  flex-basis: calc(83.33333333% - 30px);
}

/* 10/12 */
.col-11-gap {
  flex-basis: calc(91.66666667% - 30px);
}

/* 11/12 */
.col-12-gap {
  flex-basis: calc(100% - 30px);
}

/* 12/12 */

// Font sizes

.font-xxs {
  font-size: 10px;
}

.font-xs {
  font-size: 12px;
}

.font-sm {
  font-size: 14px;
}

.font-md {
  font-size: 16px;
}

.font-lg {
  font-size: 18px;
}

.font-xl {
  font-size: 20px;
}

.font-italic {
  font-style: italic;
}

// Font weights
.font-light {
  font-weight: 300;
}

.font-regular {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: bold;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container-sm {
  max-width: 540px;
}

.container-md {
  max-width: 720px;
}

.container-lg {
  max-width: 960px;
}

.container-xl {
  max-width: 1140px;
}

.container-fluid {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

/* Padding classes */
.p-0 {
  padding: 0;
}

.p-1 {
  padding: 0.25rem;
}

.p-1-5 {
  padding: 0.3rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 1rem;
}

.p-4 {
  padding: 1.5rem;
}

.p-5 {
  padding: 2rem;
}

/* Margin classes */
.m-0 {
  margin: 0;
}

.m-1 {
  margin: 0.25rem;
}

.m-2 {
  margin: 0.5rem;
}

.m-3 {
  margin: 1rem;
}

.m-4 {
  margin: 1.5rem;
}

.m-5 {
  margin: 2rem;
}

/* Padding classes */
.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-3 {
  padding-bottom: 1rem;
}

.pb-4 {
  padding-bottom: 1.5rem;
}

.pb-5 {
  padding-bottom: 2rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pl-3 {
  padding-left: 1rem;
}

.pl-4 {
  padding-left: 1.5rem;
}

.pl-5 {
  padding-left: 2rem;
}

.pl-6 {
  padding-left: 2.5rem;
}

.pl-7 {
  padding-left: 3rem;
}

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: 0.25rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pr-3 {
  padding-right: 1rem;
}

.pr-4 {
  padding-right: 1.5rem;
}

.pr-5 {
  padding-right: 2rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-3 {
  padding-top: 1rem;
}

.pt-4 {
  padding-top: 1.5rem;
}

.pt-5 {
  padding-top: 2rem;
}

/* Margin classes */
.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 2rem;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 1rem;
}

.ml-4 {
  margin-left: 1.5rem;
}

.ml-5 {
  margin-left: 2rem;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 1rem;
}

.mr-4 {
  margin-right: 1.5rem;
}

.mr-5 {
  margin-right: 2rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mt-5 {
  margin-top: 2rem;
}

/* Padding classes */
.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-4 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-5 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-4 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-5 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

/* Margin classes */
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mx-3 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-4 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-5 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-auto {
  margin-left: autp;
  margin-right: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-5 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/* Border classes */
.border {
  border: 1px solid;
}

.border-0 {
  border: 0;
}

.border-top {
  border-top: 1px solid;
}

.border-right {
  border-right: 1px solid;
}

.border-bottom {
  border-bottom: 1px solid;
}

.border-left {
  border-left: 1px solid;
}

/* Border radius classes */

.no-border {
  border-radius: 0px;
}
.rounded {
  border-radius: 0.25rem;
}

.rounded-halfrem {
  border-radius: 0.5rem;
}

.rounded-1rem {
  border-radius: 1rem;
}

.rounded-top {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.rounded-right {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rounded-left {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

/* Circle class */
.rounded-circle {
  border-radius: 50%;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-none {
  float: none;
}

.overflow-visible {
  overflow: visible;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-auto {
  overflow: auto;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

.position-static {
  position: static;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
  top: 0;
  z-index: 1000;
  /* Adjust as needed */
}

.shadow-sm {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.shadow-md {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.shadow-lg {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.shadow-xl {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

.shadow-none {
  box-shadow: none;
}

.h-100 {
  height: 100%;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.w-px {
  width: 1px;
}

.w-0 {
  width: 0;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

/* Extra small devices (phones) */
.xs-screen {
  display: none;
}

/* Small devices (portrait tablets and large phones) */
.sm-screen {
  display: none;
}

/* Medium devices (landscape tablets) */
.md-screen {
  display: none;
}

/* Large devices (laptops/desktops) */
.lg-screen {
  display: none;
}

/* Extra large devices (large laptops and desktops) */
.xl-screen {
  display: none;
}

/* Hide on extra small devices */
@media (max-width: 575.98px) {
  .xs-screen {
    display: block;
  }
}

/* Hide on small devices */
@media (min-width: 576px) and (max-width: 767.98px) {
  .sm-screen {
    display: block;
  }
}

/* Hide on medium devices */
@media (min-width: 768px) and (max-width: 991.98px) {
  .md-screen {
    display: block;
  }
}

/* Hide on large devices */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .lg-screen {
    display: block;
  }
}

/* Hide on extra large devices */
@media (min-width: 1200px) {
  .xl-screen {
    display: block;
  }
}

/* Viewport height classes */
.vh-25 {
  height: 25vh;
}

.vh-50 {
  height: 50vh;
}

.vh-75 {
  height: 75vh;
}

.vh-100 {
  height: 100vh;
}

/* Viewport width classes */
.vw-25 {
  width: 25vw;
}

.vw-50 {
  width: 50vw;
}

.vw-75 {
  width: 75vw;
}

.vw-100 {
  width: 100vw;
}

/* Min width classes */
.min-w-0 {
  min-width: 0;
}

.min-w-full {
  min-width: 100%;
}

/* Max width classes */
.max-w-none {
  max-width: none;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-full {
  max-width: 100%;
}

/* Min height classes */
.min-h-0 {
  min-height: 0;
}

.min-h-full {
  min-height: 100%;
}

/* Max height classes */
.max-h-0 {
  max-height: 0;
}

.max-h-full {
  max-height: 100%;
}

.max-h-screen {
  max-height: 100vh;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.text-center {
  text-align: center;
}

.text-wrap {
  white-space: normal;
}

.text-nowrap {
  white-space: nowrap;
}

.text-ellipsis-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

.text-ellipsis-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.text-ellipsis-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}

/* Text lowercase */
.text-lowercase {
  text-transform: lowercase;
}

/* Text uppercase */
.text-uppercase {
  text-transform: uppercase;
}

/* Text capitalize */
.text-capitalize {
  text-transform: capitalize;
}

/* Text none (remove any text transformation) */
.text-none {
  text-transform: none;
}

/* Line height classes */
.line-height-normal {
  line-height: normal;
}

.line-height-tight {
  line-height: 1;
}

.line-height-snug {
  line-height: 1.25;
}

.line-height-relaxed {
  line-height: 1.5;
}

.line-height-loose {
  line-height: 2;
}

/* Vertical alignment classes */
.vertical-align-baseline {
  vertical-align: baseline;
}

.vertical-align-top {
  vertical-align: top;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-bottom {
  vertical-align: bottom;
}

// Class
.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.clearfix {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-start;
}

.grid-item {
  flex: 1 1 100%;
  /* Full width on smaller screens */
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .grid-item-3 {
    flex: 0 0 calc(33.333% - (16px * 0.666));
    /* Three items per row, accounting for gap */
  }
  .grid-item-3-1 {
    flex: 0 0 calc(32.333% - (16px * 0.666));
    /* Three items per row, accounting for gap */
  }
}

@media (min-width: 1200px) {
  .grid-item-2 {
    flex: 0 0 calc(50% - (16px * 0.75));
    /* Four items per row, accounting for gap */
  }

  .grid-item-4 {
    flex: 0 0 calc(25% - (16px * 0.75));
    /* Four items per row, accounting for gap */
  }
}

.cursor-pointer {
  cursor: pointer;
}

.nav-cursor-pointer {
  z-index: 2;
}
