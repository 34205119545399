@import "../../styles/variables.scss";
.page-content {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0 16px;
  gap: 16px;
  .segment-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
    padding: 16px;
  }
  .no-record-found {
    display: flex;
    align-items: center;
    width: 100%;
    height: 200px;
    justify-content: center;
    font-weight: 700;
    color: grey;
    font-size: 20px;
  }
}

.segment-search-filter {
  display: flex;
  gap: 16px;
  width: 20%;
}

.segment-filters-container {
  display: inline-flex;
  gap: 22px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  .horizontal-layout {
    display: inline-flex;
    width: 27%;
    .horizontal-layoutItem-0 {
      .dropdown-container {
        height: 130px;
        width: 180px;
        z-index: 100;
      }
    }
  }
  .horizontal-layoutItem-0 {
    &.horizontal-layoutItem-0 {
      margin-top: -23px;
      display: flex;
      align-items: center;
      .MuiFormControl-root {
        .MuiOutlinedInput-root {
          .MuiOutlinedInput-notchedOutline {
            border-color: $light-blue;
            border-radius: 15px;
          }

          .MuiInputBase-input {
            color: $primary-color;

            &::placeholder {
              color: $primary-color;
              opacity: 1;
            }
          }

          .MuiInputAdornment-root {
            .MuiButtonBase-root {
              .MuiSvgIcon-root {
                color: $primary-color;
              }
            }
          }
        }

        .MuiFormLabel-root {
          position: relative; // Adjust this to ensure the label aligns properly
          top: -5px; // This is just an example; adjust as needed to move the label
        }
      }
    }
    float: left;
    width: 130px;
    margin-right: 10px;
    .multiple-select-wrap {
      width: 100%;
      .dropdown-wrapper {
        min-width: 8em;
      }
      .dropdown-title {
        width: 100%;
        margin-bottom: 5px;
        .dropdown-display-text {
          display: none;
        }
        span.icon {
          display: none;
        }
      }
      .dropdown-container {
        border-color: $light-blue;
        .dropdown-list {
          width: calc(100% - 20px);
          height: calc(100% - 20px);
          max-height: none;
          .list-item {
            width: 100%;
          }
          tbody {
            width: 100%;
            display: block;
            .\$spacing-p01 {
              padding-bottom: 10px;
              margin-bottom: 5px;
            }
            div {
              .badge {
                background: $light-blue;
              }
            }
          }
          a {
            color: $dark-gray;
          }
        }
      }
      .dropdown-input-row {
        width: 100%;
        .icon-container {
          .icon {
            color: $primary-color;
          }
          .badge {
            background: $light-blue;
          }
        }
        .dropdown-parent {
          border-radius: 15px;
          &:-moz-placeholder {
            color: $primary-color;
            opacity: 1;
          }
          &::-webkit-input-placeholder {
            color: $primary-color;
            opacity: 1;
          }
          width: 100%;
          border-color: $light-blue;
          color: $primary-color;
        }
      }
    }
  }

  /* Adjust specific elements */
  .input-search {
    flex-shrink: 0; /* Prevent shrinking */
    width: auto; /* Allow natural width */
  }

  .clear-all-button {
    font-size: 12px;
    color: #005cbc;
    font-weight: bold;
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px 8px;
    flex-shrink: 0; /* Prevent shrinking */

    &:hover {
      color: #003f8a;
    }

    &:focus {
      outline: none;
    }
  }

  .jsonforms-wrapper {
    flex: 1; /* Allow to take up remaining space */
  }

  .range-slider-control {
    width: 150px; /* Set desired width */
    flex-shrink: 0; /* Prevent shrinking */
  }
}

.input-search {
  border-top: 0;
  border-bottom: 1px solid #3a3a3a;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  &:focus {
    border-top: 0;
    border-bottom: 1px solid #005cbc;
    border-right: 0;
    border-left: 0;
  }
  &:focus-within {
    border-top: 0;
    border-bottom: 1px solid #005cbc;
    border-right: 0;
    border-left: 0;
  }
  &:active {
    border-top: 0;
    border-bottom: 1px solid #005cbc;
    border-right: 0;
    border-left: 0;
  }
  input {
    &:focus {
      &::placeholder {
        color: #005cbc;
      }
    }
    font-size: 14px;
  }
}

.grid-container {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}
