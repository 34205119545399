.multiple-select-wrap {
  .dropdown-container .dropdown-list {
    .badge {
      height: initial;
      align-items: center;
    }
    tr {
      height: initial;
      td {
        padding-top: 0.2775rem;
        padding-bottom: 0.2775rem;
      }
    }
  }
}
