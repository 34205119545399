.simple-form-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .title-wrapper {
    display: flex;
    align-items: center;

    .tooltip-wrapper {
      display: flex;
      align-items: center;

      .icon {
        margin-left: 5px;
        font-size: 18px !important;
        color: #3A3A3A;
      }
    }
  }
}
