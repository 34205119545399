.conditional-section {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  padding: 1rem;
  gap: 1.5rem;
  background-color: rgb(239, 245, 252);
  min-height: 19.32125rem;
  .condition-control-header {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    .divider {
      border: 1px solid rgb(215, 229, 248);
    }
  }
  .conditional-section-content {
    &.no-fields {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      .no-fields-label {
        font-size: 1.5rem;
        font-weight: bold;
        color: rgb(160, 160, 160);
      }
    }
  }
}
