.sweepstake-select {
  display: flex;
  flex-direction: column;
  // background-color: rgb(239, 245, 252);
  // padding: 1rem;
  border-radius: 0.5rem;
  gap: 0.25rem;
  .sweepstake-item {
    align-items: center;
    width: 100%;
    border-radius: 0.5rem;
    padding: 1rem 0.5rem;
    gap: 1rem;
    border: 1px solid rgb(215, 229, 248);
    &.sweepstake-added {
      background-color: white;
    }
    &.sweepstake-disabled {
      background-color: rgba(255, 0, 0, 0.1); /* Light red background */
      border: 1px solid red;
      color: red;

      .added-sweepstake span {
        color: red;
      }
    }
    .added-sweepstake {
      width: 100%;
    }
    .button-group button {
      justify-content: center;
      width: 2rem;
      height: 2rem;
      padding: 0.5rem;
      border-radius: 0.25rem;
    }
    .button-group-small {
      height: fit-content;
    }
  }
}

.sweekstakes-table {
  display: inline-block;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    thead {
      border-bottom: 3px solid #d7e5f8;

      tr {
        th {
          text-align: left;
          padding: 14px 5px;
          margin: 0;
          margin-bottom: 8px;
          color: #616161;
          font-size: 11px;
          text-transform: uppercase;
          width: auto;
        }
      }
    }

    tbody {
      tr {
        border-bottom: 2px solid #d7e5f8;
      }

      td {
        margin: 0;
        padding: 14px 8px;
        color: #616161;
        font-size: 0.8rem;
      }
    }
  }
}
