@import "../../../styles/variables";

.filter-container {
  .text-input .input-wrapper input {
    border-radius: 4px;
    border-color: #d7e5f8;
    color: #005cbc;
  }
  .dropdown-input-row .dropdown-parent {
    border-radius: 15px;
    width: 100%;
    border-color: #d7e5f8;
    color: #005cbc;
  }

  .MuiFormControl-root .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: #d7e5f8;
    border-radius: 15px;
    color: #005cbc;
  }
}

.code-lookup {
  .horizontal-layoutItem-0 {
    display: flex;
    align-items: center;
    padding-top: 20px;
  }
}
