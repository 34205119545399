@import "../../styles/variables";

.winning-rewards-list-container {
  height: 80%;
  overflow-y: scroll;
  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .winning-rewards-list-table {
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;

    thead {
      th {
        text-align: left;
        padding: 14px 8px;
        background: $background-light-white;
        box-shadow: 0px -2px 0px 0px rgba(215, 229, 248, 1) inset;
        margin-bottom: 8px;
        color: $dark-gray;
        font-size: 0.8rem;
        text-transform: uppercase;

        &.column-name {
          width: 70%; // Allocate more space for the Title column
        }

        &.column-actions {
          width: 30%; // Adjust width for the actions column
        }
      }
    }

    tbody {
      box-shadow: 0px 0px 24px 0px rgba(0, 92, 188, 0.08);
      background: $background-light-white;

      td {
        background: $background-light-white;
        box-shadow: 0px -2px 0px 0px rgba(215, 229, 248, 1) inset;
        padding: 8px;
        color: $dark-gray;
        font-size: 0.8rem;

        &.reward-name {
          max-width: 500px; // Limit the width for the title text
          height: 54px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .button-group-wrapper {
          display: flex;
          justify-content: flex-start; // Align buttons to the left
          gap: 8px; // Add some spacing between buttons

          button {
            padding: 8px 16px;
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}
