.segment-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  background-color: #fbfcfe;
  border-radius: 8px;
  gap: 16px;
  padding: 16px;
  .segment-name {
    font-weight: 700;
    font-size: 12px;
  }
}

.segment-item img {
  width: 5.25rem;
  height: 2.8rem;
  border-radius: 0.5rem;
  object-fit: cover;
  margin-bottom: 8px;
}

.campaign-item {
  margin-bottom: 8px;
}
.campaign-summary-card {
  padding: 0;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  background: red;
}
.summery-card-container.campaign-summary-page {
  .summary-card-details {
    .additional-fields-container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 4px 0;
      .field-pair {
        flex-basis: 30% !important;
        .field-label {
          font-size: 12px;
        }
        @media screen and (min-width: 1300px) {
          flex-basis: 25% !important;
        }
      }
      .field-pair-two-column {
        flex-basis: 60% !important;
        .field-value {
          font-size: 12px;
          color: #666;
        }
      }
    }
    .field-value {
      font-size: 12px;
    }
  }
}
.campaign-name {
  height: auto;
}
.campaign-definition-name {
  font-size: 14px;
  font-weight: 700;
  color: #333;
  padding-bottom: 10px;
  border-bottom: 2px solid #e0e0e0;
  margin-bottom: 10px;
}
.summary-fields {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}
.summary-label {
  width: 20%;
}
.summary-value {
  color: #666;
  margin-top: 3px;
  font-size: 12px;
}
.summary-image {
  margin: 0 20px 10px 0;
  .field-label {
    font-size: 12px;
  }
}
.activity-definition-name {
  font-size: 12px;
  font-weight: 600;
  color: #333;
  padding-bottom: 10px;
  margin: 0 0 0px 0;
}
