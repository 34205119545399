.partner-code-modal .modal {
  width: auto;
}

.file-upload {
  box-sizing: border-box;
  color: var(--text-color-text-secondary, #616161);
  width: max-content;
}

.page-content {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0 16px;
  gap: 16px;
}

.search-filter-container {
  display: flex;
  gap: 16px;
  width: 100%;
  align-items: center;
  .partner-filters {
    .horizontal-layoutItem-item {
      max-width: 17.625rem;
    }
  }
  .input-search {
    max-width: 30%;
  }
  .dropdown-input-row {
    width: 100%;
    input {
      border-radius: 15px;
      width: 100%;
      border: 1px solid #d7e5f8;
      color: #005cbc;
    }
    span {
      color: #005cbc;
    }
  }
  .dropdown-title {
    display: none;
  }
  .vertical-layoutItem-item {
    float: left;
    width: 130px;
  }
  .vertical-layout {
    align-items: flex-end;
    justify-content: center;
    flex-direction: row;
  }
  .MuiOutlinedInput-root {
    input,
    svg {
      color: #005cbc;
    }
  }
  .vertical-layoutItem-item.horizontal-layoutItem-3
    .MuiFormControl-root
    .MuiOutlinedInput-root
    .MuiInputBase-input {
    color: #005cbc;
  }
  .vertical-layoutItem-item.horizontal-layoutItem-3
    .MuiFormControl-root
    .MuiOutlinedInput-root
    .MuiOutlinedInput-notchedOutline {
    border-color: #d7e5f8;
  }
  input::placeholder {
    color: #005cbc;
  }
}

.input-search {
  border-top: 0;
  border-bottom: 1px solid #3a3a3a;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  &:focus {
    border-top: 0;
    border-bottom: 1px solid #005cbc;
    border-right: 0;
    border-left: 0;
  }
  &:focus-within {
    border-top: 0;
    border-bottom: 1px solid #005cbc;
    border-right: 0;
    border-left: 0;
  }
  &:active {
    border-top: 0;
    border-bottom: 1px solid #005cbc;
    border-right: 0;
    border-left: 0;
  }
  input {
    &:focus {
      &::placeholder {
        color: #005cbc;
      }
    }
    font-size: 14px;
  }
}

.card {
  .card-header {
    display: none;
  }
  .card-footer {
    display: none;
  }
  .card-content {
    align-items: initial;
  }
}

.card .card-footer {
  display: none;
}

.card {
  .partner-header {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .status {
      width: fit-content;
      font-size: 10px;
      border-radius: 4px;
      padding: 2px 4px;
      &.draft {
        background-color: rgb(97, 97, 97);
      }
      &.inactive {
        background-color: rgb(156, 156, 156);
      }
      &.scheduled {
        background-color: rgb(255, 204, 73);
        color: rgb(58, 58, 58);
      }
      &.completed {
        background-color: rgb(179, 218, 203);
        color: rgb(58, 58, 58);
      }
      &.ended {
        background-color: rgb(102, 126, 136);
      }
    }
  }

  .partner-info {
    display: flex;
    gap: 10px;
    .meta-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
    }
  }

  .button-group-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 0px;
    &.deletable {
      .button-group button:last-child {
        color: #d40020;
      }
    }
  }
}
.status {
  width: fit-content;
  font-size: 10px;
  border-radius: 4px;
  padding: 2px 4px;
  &.draft {
    background-color: rgb(97, 97, 97);
  }
  &.scheduled {
    background-color: rgb(255, 204, 73);
    color: rgb(58, 58, 58);
  }
  &.completed {
    background-color: rgb(179, 218, 203);
    color: rgb(58, 58, 58);
  }
  &.ended {
    background-color: rgb(102, 126, 136);
  }
}

.button-group-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 0px;
  &.deletable {
    .button-group button:last-child {
      color: #d40020;
    }
  }
}

.button-group-wrapper {
  &.deletable {
    .button-group button:last-child {
      color: #d40020;
    }
  }
}

.datatable {
  .header {
    display: none;
  }
  .footer {
    display: none;
  }
}

.grid-container {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

.empty-state-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.empty-state {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(91.5vh - 13.6rem);
  img {
    width: 14.125rem;
    height: 14.8125rem;
  }
  p {
    font-size: 2rem;
    font-weight: 700;
    color: rgb(160, 160, 160);
  }
}

.pagination {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}
