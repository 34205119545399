.array-of-fields-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .array-of-fields-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    border-radius: 0.5rem;
    padding: 1rem;
    background-color: rgb(239, 245, 252);
    &.add-section {
      padding: 1rem 0.5rem;
    }
    .button-group button {
      justify-content: center;
      width: 2rem;
      height: 2rem;
      padding: 0.5rem;
      border-radius: 0.25rem;
    }
    .delete-item-button {
      align-self: flex-end;
      margin-bottom: 0.75rem;
    }
    .button-group-small {
      height: fit-content;
    }
    .add-item-label {
      font-size: 0.75rem;
      font-weight: bold;
    }
  }
}
